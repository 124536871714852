const routes = [
  {
    name: 'FlowList',
    path: '/',
    component: () => (window.$rebranding.active ? import('../views/rebranding/flows/List.vue') : import('../views/flows/List.vue')),
  },
  {
    name: 'FlowCreate',
    path: 'create',
    component: () => import('../views/flows/Old.vue'),
  },
  {
    name: 'FlowTemplates',
    path: 'templates',
    component: () => import('../views/rebranding/flows/Templates.vue'),
  },
  {
    name: 'FlowEdit',
    path: ':id',
    component: () => import('../views/flows/CreateEdit.vue'),
  },
  {
    name: 'FlowEditOld',
    path: ':id',
    component: () => import('../views/flows/Old.vue'),
  },
  {
    name: 'FlowReports',
    path: 'reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/flows/reports/Index.vue'),
    children: [
      {
        name: 'FlowStatReport',
        path: 'stat',
        component: () => import('@/views/flows/reports/Stat.vue'),
      },
      {
        name: 'FlowHistoryReport',
        path: 'history',
        component: () => import('@/views/flows/reports/History.vue'),
      },
      {
        name: 'FlowDetail',
        path: 'history/:id',
        component: () => import('@/views/flows/reports/Detail.vue'),
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
      },
      {
        name: 'FlowUsageReport',
        path: 'usage',
        component: () => import('@/views/flows/reports/Usage.vue'),
      },
      {
        name: 'FlowReportExports',
        path: 'exports',
        component: () => import('@/views/flows/reports/Export.vue'),
      },
    ],
  },
];

export default routes;
