import Messages from './messages';
import Campaigns from './campaigns';
import Reports from './reports';
import Numbers from './numbers';
import Templates from './templates';

export default ($axios) => ({
  messages: Messages($axios),
  campaigns: Campaigns($axios),
  reports: Reports($axios),
  numbers: Numbers($axios),
  templates: Templates($axios),
});
