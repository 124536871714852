<template>
  <div class="content">
    <PageHeader :title="$tc('generic-str.detail', 2)" />
    <div class="page-content container-fluid">
      <sms-properties-card />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import SmsPropertiesCard from '@/components/sms/PropertiesCard.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    SmsPropertiesCard,
  },
  data() {
    return {};
  },
};
</script>
