<template>
  <div class="card">
    <h5 class="card-header">{{$t('sms.dashboard.lbl-recent-msg')}}</h5>
    <div class="card-body block-el">
      <div v-if="loaded" class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>{{$t('generic-str.date')}}</th>
              <th>ID</th>
              <th>{{$t('generic-str.type')}}</th>
              <th>{{$t('generic-str.lbl-sender')}}</th>
              <th>{{$t('generic-str.lbl-recipient')}}</th>
              <th>{{$tc('generic-str.part', 2)}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>15:55:48 2020-04-09</td>
              <td>SM58bc4263d242bb7ff59dedb6e986f788</td>
              <td>{{$t('generic-str.send-2')}}</td>
              <td>+62733</td>
              <td>+5513981327195</td>
              <td>1</td>
            </tr>
            <tr>
              <td>15:55:48 2020-04-09</td>
              <td>SM58bc4263d242bb7ff59dedb6e986f788</td>
              <td>{{$t('generic-str.send-2')}}</td>
              <td>+5513981327195</td>
              <td>+62733</td>
              <td>1</td>
            </tr>
            <tr>
              <td>15:55:48 2020-04-09</td>
              <td>SM58bc4263d242bb7ff59dedb6e986f788</td>
              <td>{{$t('generic-str.send-2')}}</td>
              <td>+62733</td>
              <td>+5513981327195</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="qt-block-ui" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QueueCard',
  data() {
    return {
      loaded: false,
    };
  },
  async mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 200);
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
</style>
