export default ($axios) => ({
  fetch(query) {
    return $axios.get('/flow/streams', { params: query }).then((response) => response.data);
  },
  find(streamId) {
    return $axios.get(`/flow/streams/${streamId}`).then((response) => response.data);
  },
  cancel(streamId) {
    return $axios.put(`/flow/streams/${streamId}`, { status: 'cancelled' }).then((response) => response.data);
  },
  insights(payload) {
    return $axios.post('/flow/streams/insights', payload).then((response) => response.data);
  },
});
