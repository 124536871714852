export default {
  state: {
    list: [],
  },
  mutations: {
  },
  actions: {
    fetchInvoices({ commit, state }, payload) {
      return this._vm.$api.billing.fetchInvoices(payload);
    },
  },
};
