<template>
  <div>
    <div class="card">
      <h5 class="card-header">{{$t('properties.title')}}</h5>
      <div class="card-body block-el">
        <form v-if="loaded" action="#" class="form-horizontal">
          <div class="row">
            <div class="col-md-6">
              <div class="form-body">
                <div class="form-group row">
                  <label class="control-label text-right text-left-m col-md-3 weight-500">ID</label>
                  <label class="control-label text-left col-md-9">{{ $route.params.id }}</label>
                </div>
                <div class="form-group row">
                  <label
                    class="control-label text-right text-left-m col-md-3 weight-500"
                  >{{$t('generic-str.sent-in')}}</label>
                  <label class="control-label text-left col-md-9">{{ message.sent_at | formatDate24}}</label>
                </div>
                <div class="form-group row">
                  <label
                    class="control-label text-right text-left-m col-md-3 weight-500"
                  >{{$t('generic-str.lbl-sender')}}</label>
                  <label class="control-label text-left col-md-9">{{ message.from }}</label>
                </div>
                <div class="form-group row">
                  <label
                    class="control-label text-right text-left-m col-md-3 weight-500"
                  >{{$t('generic-str.lbl-recipient')}}</label>
                  <label class="control-label text-left col-md-9">{{ message.to }}</label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-body">
                <div class="form-group row">
                  <label
                    class="control-label text-right text-left-m col-md-3 weight-500"
                  >{{$t('generic-str.type')}}</label>
                  <label class="control-label text-left col-md-9">
                    <template v-if="message.direction == 'outbound'">{{$t('generic-str.send-2')}}</template>
                    <template v-else-if="message.direction == 'inbound'">{{$tc('generic-str.status.lbl-response', 1)}}</template>
                  </label>
                </div>
                <div class="form-group row">
                  <label
                    class="control-label text-right text-left-m col-md-3 weight-500"
                  >{{$t('generic-str.lbl-cost')}}</label>
                  <label
                    class="control-label text-left col-md-9"
                  >R${{ message.price || currency4D }}</label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-body">
                <div class="form-group row">
                  <label
                    class="control-label text-right text-left-m col-md-3 weight-500"
                  >{{$t('generic-str.content')}}</label>
                  <label class="control-label text-left col-md-9">{{ message.content }}</label>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div v-else class="qt-block-ui" />
      </div>
    </div>

    <div class="card" v-if="message.type == 'location'">
      <div class="card-body">
        <h2>Localização</h2>
        <div class="form-group row" style="padding-left: 18px; padding-right: 18px">
          <GmapMap
            :center="{lat:10, lng:10}"
            :zoom="7"
            map-type-id="terrain"
            style="width: 500px; height: 300px"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center=m.position"
            />
          </GmapMap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WhatsService from '@/services/whatsapp.service';
import * as VueGoogleMaps from 'vue2-google-maps';

export default {
  name: 'HistoryCard',
  components: {
  },
  data() {
    return {
      loaded: false,
      message: {},
      markers: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      WhatsService.getMessage(this.$route.params.id).then(
        (response) => {
          this.loaded = true;
          console.log(response);
          this.message = response.data;
          if (this.message.type === 'location') {
            this.markers.push(
              {
                lat: this.message.location.latitude,
                lng: this.message.location.longitude,
              },
            );
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
</style>
