import EmailHistory from '../views/mail/History.vue';

const routes = [
  {
    name: 'MailSend',
    path: 'send',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/Send.vue'),
  },
  {
    name: 'MailInsights',
    path: 'insights',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/Insights.vue'),
  },
  {
    name: 'MailTemplates',
    path: 'templates',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/mail/Templates.vue') : import('../views/mail/Templates.vue')),
  },
  {
    name: 'MailReport',
    path: 'dashboard',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/Dashboard.vue'),
  },
  {
    name: 'EmailHistory',
    path: 'history',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/History.vue'),
  },
  {
    name: 'ShowEmail',
    path: 'history/:id',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/Detail.vue'),
  },
  {
    name: 'EmailCampaigns',
    path: 'campaigns',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/Campaigns.vue'),
  },
  {
    name: 'EmailExports',
    path: 'exports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/Exports.vue'),
  },
  {
    name: 'EmailCampaignDetail',
    path: 'campaigns/:id',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/CampaignDetail.vue'),
  },
  {
    name: 'MailSuccess',
    path: 'send/success',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/Success.vue'),
  },
  {
    name: 'EditTemplate',
    path: 'templates/create/:id/:isLib?/:isCopy?',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/NewTemplate.vue'),
  },
  {
    name: 'MailNewTemplate',
    path: 'templates/create',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/NewTemplate.vue'),
  },
  {
    name: 'EmailDomains',
    path: 'domains',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/Domains.vue'),
  },
  {
    name: 'MailDanyList',
    path: 'denylist',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/mail/Blacklist.vue') : import('../views/mail/Blacklist.vue')),
  },
  {
    name: 'MailReports',
    path: 'reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rebranding/mail/reports/Index.vue'),
    children: [
      {
        name: 'MailStatReport',
        path: 'stat',
        component: () => import('../views/rebranding/mail/reports/Stat.vue'), // --> /mail/insights
      },
      {
        name: 'MailHistory',
        path: 'history',
        component: () => import('../views/rebranding/mail/reports/History.vue'), // -- /mail/history
      },
      {
        name: 'MailCampaigns',
        path: 'campaigns',
        component: () => (window.$rebranding.active ? import('../views/rebranding/mail/reports/Campaigns.vue') : import('../views/mail/Campaigns.vue')), // -- /mail/campaigns
      },
      {
        name: 'MailReportExports',
        path: 'exports',
        component: () => import('@/views/rebranding/mail/reports/Export.vue'),
      },
      {
        name: 'MailCampaigns',
        path: 'usage',
        component: () => (window.$rebranding.active ? import('../views/rebranding/mail/reports/Campaigns.vue') : import('../views/mail/Campaigns.vue')), // -- /mail/campaigns
      },
    ],
  },
  /*
  {
    name: 'MailReports',
    path: 'reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rebranding/mail/reports/Index.vue'),
    children: [
      {
        name: 'MailStatReport',
        path: 'stat',
        component: () => import('@/views/rebranding/mail/reports/Stat.vue'),
      },
      {
        name: 'MailHistoryReport',
        path: 'history',
        component: () => import('@/views/rebranding/mail/reports/History.vue'),
      },
      {
        name: 'MailUsageReport',
        path: 'usage',
        component: () => import('@/views/rebranding/mail/reports/Usage.vue'),
      },
      {
        name: 'MailCampaignReport',
        path: 'campaign',
        component: () => import('@/views/rebranding/mail/reports/Campaign.vue'),
      },
    ],
  },
  */
  {
    name: 'EmailUsage',
    path: 'usage',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    // component: EmailUsage,
    component: () => import('../views/rebranding/mail/Usage.vue'),
    children: [
      {
        name: 'EmailReport',
        path: '',
        component: () => import('@/views/rebranding/mail/Report.vue'),
      },
      {
        name: 'EmailStats',
        path: 'stats',
        component: () => import('@/views/rebranding/mail/stats/Index.vue'),
      },
      {
        name: 'EmailHistoryTab',
        path: 'history',
        component: () => import('@/views/rebranding/mail/history/History.vue'),
      },
    ],
  },
  {
    name: 'MailOptouts',
    path: 'optouts',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/mail/Optouts.vue'),
  },
];

export default routes;
