/* jshint sub:true */
class Permissions {
  constructor() {
    this.permissions = {
      user: {
        path_permission: [
          '/',
          '/billing',
          '/invoices',
          '/usage',
          '/sms/dashboard',
          '/sms/insights',
          '/sms/campaigns',
          '/sms/history',
          '/sms/usage',
          '/sms/send',
          '/sms/short-codes',
          '/sms/templates',
          '/sms/danylist',
          '/sms/optouts',
          '/sms/smpp',
          '/whatsapp',
          '/whatsapp/insights',
          '/whatsapp/campaigns',
          '/whatsapp/send',
          '/whatsapp/templates',
          '/whatsapp/history',
          '/whatsapp/danylist',
          '/whatsapp/optouts',
          '/rcs',
          '/rcs/insights',
          '/rcs/send',
          '/rcs/campaigns',
          '/rcs/history',
          '/rcs/danylist',
          '/rcs/optouts',
          '/mail/dashboard',
          '/mail/send',
          '/mail/insights',
          '/mail/campaigns',
          '/mail/history',
          '/mail/templates',
          '/mail/danylist',
          '/mail/optouts',
          '/contacts',
          '/contacts/groups',
          '/contacts/import',
          '/live',
          '/ra',
        ],
      },
      developer: {
        path_permission: [
          '/',
          '/billing',
          '/invoices',
          '/usage',
          '/sms/dashboard',
          '/sms/insights',
          '/sms/campaigns',
          '/sms/history',
          '/sms/usage',
          '/whatsapp',
          '/whatsapp/insights',
          '/whatsapp/campaigns',
          '/whatsapp/history',
          '/rcs',
          '/rcs/insights',
          '/rcs/campaigns',
          '/rcs/history',
          '/mail/dashboard',
          '/mail/insights',
          '/mail/campaigns',
          '/mail/domains',
          '/mail/history',
          '/contacts',
          '/contacts/groups',
          '/contacts/import',
          '/live/configs/widget',
          '/live/configs/widget/config',
          '/webhooks',
          '/live',
          '/ra',
        ],
      },
      billing: {
        path_permission: [
          '/',
          '/billing',
          '/invoices',
          '/usage',
          '/sms/dashboard',
          '/sms/insights',
          '/sms/campaigns',
          '/sms/history',
          '/sms/usage',
          '/whatsapp',
          '/whatsapp/insights',
          '/whatsapp/campaigns',
          '/whatsapp/history',
          '/rcs',
          '/rcs/insights',
          '/rcs/campaigns',
          '/rcs/history',
          '/mail/dashboard',
          '/mail/insights',
          '/mail/campaigns',
          '/mail/history',
          '/contacts',
          '/contacts/groups',
          '/contacts/import',
          '/live/configs/widget',
          '/live/configs/widget/config',
          '/webhooks',
          '/live',
          '/ra',
        ],
      },
      admin: {
        path_permission: [
          '/invoices',
          '/billing',
          '/users',
          '/usage',
          '/settings',
          '/chat/widget',
          '/customer/products',
          '/support/new-ticket',
          '/sms/smpp',
          '/support/messages-ticket',
          '/invoices',
          '/webhooks',
          '/customers',
          '/sms/send',
          '/sms/campaigns',
          '/sms/exports',
          '/sms/templates',
          '/tickets',
          '/sms/queue',
          '/sms/usage',
          '/sms/history',
          '/sms/antifraude',
          '/sms/blacklist',
          '/sms/optouts',
          '/admin/customers',
          '/mail/domains',
          '/mail/send',
          '/mail/templates',
          '/contacts',
          '/admin/tickets',
          '/live',
          '/ra',
        ],
      },
      sales: {
        path_permission: [
          '/invoices',
          '/billing',
          '/users',
          '/usage',
          '/settings',
          '/chat/widget',
          '/customer/products',
          '/support/new-ticket',
          '/sms/smpp',
          '/support/messages-ticket',
          '/invoices',
          '/webhooks',
          '/customers',
          '/sms/send',
          '/sms/campaigns',
          '/sms/exports',
          '/sms/templates',
          '/tickets',
          '/sms/queue',
          '/sms/usage',
          '/sms/history',
          '/sms/antifraude',
          '/sms/blacklist',
          '/sms/optouts',
          '/admin/customers',
          '/mail/domains',
          '/mail/send',
          '/mail/templates',
          '/contacts',
          '/admin/tickets',
          '/live',
          '/ra',
        ],
      },
    };
    this.groupPermission = {
      user: {
        groupPermission: [
          'lookup',
          'manager',
          'settings',
        ],
      },
      developer: {
        groupPermission: [
          'contacts',
          'lookup',
          'manager',
        ],
      },
      billing: {
        groupPermission: [
          'contacts',
          'lookup',
          'manager',
          'live',
          'settings',
        ],
      },
    };
  }

  isRole(role, path) {
    console.log('Role', role);
    this.a = true;
    switch (role) {
      case 'developer':
        return this.permissions.developer.path_permission.includes(path);
      case 'billing':
        return this.permissions.billing.path_permission.includes(path);
      case 'user':
        return this.permissions.user.path_permission.includes(path);
      default:
    }
    return true;
  }

  isRoleGroup(role, group) {
    this.a = true;
    switch (role) {
      case 'developer':
        return !this.groupPermission.developer.groupPermission.includes(group);
      case 'billing':
        return !this.groupPermission.billing.groupPermission.includes(group);
      case 'user':
        return !this.groupPermission.user.groupPermission.includes(group);
      default:
    }
    return true;
  }

  checkPlanPermission(plan, path) {
    this.a = true;
    if (path.indexOf('/live/configs/opening-hours') !== -1) {
      if (!plan.permissions.opening_hour) {
        return false;
      }
    }
    if (path.indexOf('/live/dashboard') !== -1) {
      if (!plan.permissions.dashboard) {
        return false;
      }
    }
    if (path.indexOf('/live/manage') !== -1) {
      if (!plan.permissions.spie) {
        return false;
      }
    }
    if (path.indexOf('/configs/tabulations') !== -1) {
      if (!plan.permissions.tab) {
        return false;
      }
    }
    if (path.indexOf('/departments') !== -1) {
      if (!plan.permissions.department) {
        return false;
      }
    }
    return true;
  }
}
export default new Permissions();
