export default {
  state: {
    list: [],
  },
  mutations: {
  },
  actions: {
    fetchOmniRooms({ commit, state }, query) {
      return this._vm.$api.omni.rooms.fetchRooms(query);
    },
    fetchOmniRoom({ commit, state }, roomId) {
      return this._vm.$api.omni.rooms.fetchRoom(roomId);
    },
    fetchOmniRoomMessages({ commit, state }, roomId) {
      return this._vm.$api.omni.rooms.fetchRoomMessages(roomId);
    },
    fetchOmniTabs({ commit, state }, query) {
      return this._vm.$api.omni.tabs.fetchTabs(query);
    },
    fetchOmniTags({ commit, state }, query) {
      return this._vm.$api.omni.tags.fetchTags(query);
    },
    fetchOmniAgents({ commit, state }, query) {
      return this._vm.$api.omni.agents.fetchAgents(query);
    },
  },
};
