import Vue from 'vue';
import axiosLib from 'axios';
import axios from '@/plugins/axios';

import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';

const webApi = axios();

const NOTIFICATION_WEBSOCKET = process.env.VUE_APP_NOTIFICATION_WEBSOCKET.replace(/\/+$/, '');

export default new Vue({
  emits: {
    requestRoom: null,
  },
  watch: {
  },
  computed: {
    accountId() {
      return this.contextApp?.$store?.state?.account?.id;
    },
  },
  data() {
    return {
      lastAccountId: '',
      ready: false,
      starting: false,
    };
  },
  methods: {
    async reconnect() {
      console.log('reconnect notify');
      await this.disconnect();
      this.preConnect();
    },
    setup(context) {
      this.contextApp = context;
      this.preConnect();
    },
    preConnect() {
      const accountId = this.accountId;
      console.log('Conectando notify', this.accountId);
      // console.log('preConnect', accountId);

      if (accountId) {
        // console.log('preConnect', 'AccountService.getInfo', accountId);
        this.lastAccountId = accountId;
        this.connect();
      } else {
        setTimeout(() => this.preConnect(), 500);
      }
    },
    async connect() {
      console.log('Account', this.contextApp?.$store?.state?.account?.id);
      if (this.ready || this.starting) return;

      this.starting = true;

      try {
        this.startSocket();
      } catch (ee) {
        console.log('Error connect notify');
        console.log(ee);
        this.starting = false;
      }
    },
    async disconnect() {
      console.log('disconnect notify');

      if (this.stompClient) {
        this.stompClient.disconnect(() => {});
        this.stompClient = null;
      }
      this.ready = false;
      this.starting = false;
    },
    startSocket() {
      console.log('startSocket Notify');

      if (this.accountId) {
        this.startSocketNext();
      } else {
        setTimeout(() => {
          this.startSocket();
        }, 500);
      }
    },
    startSocketNext() {
      const accountId = this.accountId;
      this.socket = new SockJS(NOTIFICATION_WEBSOCKET, null, {});

      this.stompClient = Stomp.over(this.socket, {
        protocols: Stomp.VERSIONS.supportedProtocols(),
        debug: true,
      });

      this.stompClient.connect({}, (frame) => {
        console.log('startSocketNextNotify::connect');
        this.ready = true;
        this.reconnecting = -1;
        this.errorConnection = false;
        this.starting = false;
        this.stompClient.subscribe(`/topic/${accountId}/notify`, (output) => {
          this.notify(output);
          this.checkConnection();
        });

        this.firstLaunch = false;
      }, async (error) => {
        console.error('stompClientNotify:connect', error);
        this.reconnectAfter(10);
        this.errorConnection = true;
      });

      // this.stompClient.disConnect();
    },
    async reconnectAfter(sec) {
      this.reconnecting = sec;

      if (sec === 5) this.socket = null;

      if (sec === 0) {
        if (this.stompClient) this.stompClient.disconnect(() => {});

        if (this.socket) this.socket.close();

        setTimeout(() => {
          this.stompClient = null;
          this.socket = null;
          this.startSocket();
        }, 100);
      } else {
        sec -= 1;
        setTimeout(() => {
          this.reconnectAfter(sec);
        }, 1000);
      }
    },
    checkConnection() {
      if (this.checkingConnection || this.userDisconnect) return;

      this.checkingConnection = true;

      const interval = setInterval(() => {
        const store = this.contextApp.$store;
        const path = this.contextApp.$router.history.current.path;
        const logged = store.state?.auth?.token;
        // desconecta do live se tiver feito logoff
        if (path.indexOf('/login') === 0 || !logged) {
          this.disconnect();
        } else if (logged) {
          if (this.accountId !== this.lastAccountId) {
            // Reconecta (quando necessário) se mudar a conta atual
            this.reconnect();
          }
        }
      }, 2000);
    },
    notify(notification) {
      this.contextApp.$root.$emit('notify:new', JSON.parse(notification.body));
    },
  },
});
