<template>
  <form v-if="loaded" action="#" class="form-horizontal">
    <div class="row">
      <div class="col-md-6">
        <div class="form-body">
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-500"
              >ID</label
            >
            <label class="control-label text-left col-md-9">{{
              $route.params.id
            }}</label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-500"
              >{{ $t('generic-str.sent-in') }}</label
            >
            <label class="control-label text-left col-md-9">{{
              message.created_at
            }}</label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-500"
              >{{ $t('generic-str.lbl-sender') }}</label
            >
            <label class="control-label text-left col-md-9">{{
              message.from
            }}</label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-500"
              >{{ $t('generic-str.lbl-recipient') }}</label
            >
            <label class="control-label text-left col-md-9">{{
              message.to
            }}</label>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-body">
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-500"
              >{{ $t('generic-str.type') }}</label
            >
            <label class="control-label text-left col-md-9">
              <template v-if="message.direction == 'outbound'">{{
                $t('generic-str.send-2')
              }}</template>
              <template v-else-if="message.direction == 'inbound'">{{
                $tc('generic-str.status.lbl-response', 1)
              }}</template>
            </label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-500"
              >{{ $tc('generic-str.part', 2) }}</label
            >
            <label class="control-label text-left col-md-9">{{
              message.segments
            }}</label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-500"
              >{{ $t('generic-str.coding') }}</label
            >
            <label class="control-label text-left col-md-9">GSM</label>
          </div>
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-500"
              >{{ $t('generic-str.lbl-cost') }}</label
            >
            <label class="control-label text-left col-md-9"
              >R${{ message.price || currency4D }}</label
            >
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-body">
          <div class="form-group row">
            <label
              class="control-label text-right text-left-m col-md-3 weight-500"
              >{{ $t('generic-str.content') }}</label
            >
            <label class="control-label text-left col-md-9">{{
              message.body
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import SmsService from '@/services/sms.service';

export default {
  name: 'HistoryCard',
  data() {
    return {
      loaded: false,
      message: {},
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      SmsService.getMessage(this.$route.params.id).then(
        (response) => {
          this.loaded = true;
          this.message = response.data;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
</style>
