export default [
  {
    name: 'Customers',
    path: 'customers',
    component: () => import('@/views/admin/customers/Index.vue'),
  },
  {
    name: 'CreateCustomer',
    path: 'customers/create',
    component: () => import('@/views/admin/customers/Create.vue'),

  },
  {
    name: 'CustomerShow',
    path: 'customers/:customerId',
    component: () => import('@/views/admin/customers/Show.vue'),
    children: [
      {
        name: 'CustomerEdit',
        path: '',
        component: () => import('@/views/admin/customers/Edit.vue'),
      },
      {
        name: 'CustomerShortCodes',
        path: 'shortcodes',
        component: () => import('@/views/admin/customers/short-codes/Index.vue'),
      },
      {
        name: 'CustomerPlans',
        path: 'plans',
        component: () => import('@/views/admin/customers/plans/Index.vue'),
      },
      {
        name: 'CustomerProducts',
        path: 'products',
        component: () => import('@/views/admin/customers/products/Index.vue'),
      },
      {
        name: 'CustomerInvoices',
        path: 'invoices',
        component: () => import('@/views/admin/customers/Invoices.vue'),
      },
    ],
  },
  {
    name: 'AssignProduct',
    path: 'customers/:id/products/create',
    component: () => import('@/views/admin/customers/AssignProduct.vue'),
  },
  {
    name: 'AssignShortCode',
    path: 'customers/:id/short-codes/create',
    component: () => import('@/views/admin/customers/AssignShortCode.vue'),
  },
  {
    name: 'AttachPlan',
    path: 'customers/:id/plans/create',
    component: () => import('@/views/admin/customers/AttachPlan.vue'),
  },
  {
    name: 'EditPlan',
    path: 'customers/:customerId/plans/:planId',
    component: () => import('@/views/admin/customers/EditPlan.vue'),
  },
  {
    name: 'ShowCustomerShortCode',
    path: 'customers/:customerId/short-codes/:shortCodeId',
    component: () => import('@/views/admin/customers/ShowShortCode.vue'),
  },
  {
    name: 'ShowAdminProduct',
    path: 'customers/:customerId/products/:productId',
    component: () => import('@/views/admin/customers/ShowProduct.vue'),
  },
  {
    name: 'ShowAssigned',
    path: 'customers/:customerId/numbers/:numberId',
    component: () => import('@/views/admin/customers/Show.vue'),
  },
];
