import Toaster from './Toaster.vue';

const ToastPlugin = {
  install(Vue) {
    const vm = Vue;
    vm.prototype.$toast = new Toaster();
  },
};

export default ToastPlugin;
