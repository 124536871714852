const routes = [
  {
    name: 'Pipeline',
    path: '/',
    component: () => import('../views/rebranding/workflow/Workspace.vue'),
  },
  {
    name: 'PipelineBoard',
    path: ':id',
    component: () => import('../views/rebranding/workflow/Board.vue'),
  },
  {
    name: 'PipelineBoardDetail',
    path: ':id/deals/:id',
    component: () => import('../views/rebranding/workflow/detail/BoardDetail.vue'),
  },
  {
    name: 'PipelineReports',
    path: 'reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rebranding/workflow/reports/Index.vue'),
    children: [
      {
        name: 'PipelineStatReport',
        path: 'stat',
        component: () => import('@/views/rebranding/workflow/reports/detailed/Stat.vue'),
      },
      {
        name: 'PipelineHistoryReport',
        path: 'history',
        component: () => import('@/views/rebranding/workflow/reports/detailed/History.vue'),
      },
      {
        name: 'PipelineDetail',
        path: 'history/:id',
        component: () => import('@/views/rebranding/workflow/reports/detailed/Detail.vue'),
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
      },
      {
        name: 'PipelineUsageReport',
        path: 'usage',
        component: () => import('@/views/rebranding/workflow/reports/detailed/Usage.vue'),
      },
      {
        name: 'PipelineCampaignReport',
        path: 'campaign',
        component: () => import('@/views/rebranding/workflow/reports/detailed/Campaign.vue'),
      },
      {
        name: 'PipelineReportExports',
        path: 'exports',
        component: () => import('@/views/rebranding/workflow/reports/detailed/Export.vue'),
      },
    ],
  },
];

export default routes;
