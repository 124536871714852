const routes = [
  {
    name: 'NumbersActive',
    path: 'active',
    meta: {
      role: 'admin',
    },
    component: () => import('../views/numbers/Active.vue'),
  },
];

export default routes;
