export default [
  {
    name: 'Billing',
    path: 'billing',
    meta: {
      role: 'admin,owner,billing,developer,user,sales',
    },
    component: () => (window.$rebranding.active ? import('@/views/rebranding/billing/Index.vue') : import('@/views/billing/Index.vue')),
  },
  {
    name: 'BillingPlan',
    path: 'billing/plans/:planId',
    meta: {
      role: 'admin,owner,billing,developer,user,sales',
    },
    component: () => (window.$rebranding.active ? import('@/views/rebranding/billing/Plan.vue') : import('@/views/billing/Plan.vue')),
  },
];
