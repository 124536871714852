export default {
  state: {
  },
  mutations: {
  },
  actions: {
    fetchRcsMessages({ commit, state }, query) {
      return this._vm.$api.rcs.messages.fetch(query);
    },
    fetchRcsReports({ commit, state }, query) {
      return this._vm.$api.rcs.reports.fetch(query);
    },
    exportRcsMessages({ commit, state }, query) {
      return this._vm.$api.rcs.messages.export(query);
    },
    downloadRcsReport({ commit, state }, id) {
      return this._vm.$api.rcs.reports.download(id);
    },
    fetchRcsMessage({ commit, state }, id) {
      return this._vm.$api.rcs.messages.find(id);
    },
    fetchRcsAgents({ commit, state }, query) {
      return this._vm.$api.rcs.agents.fetch(query);
    },
    fetchRcsInsights({ commit, state }, query) {
      return this._vm.$api.rcs.messages.insights(query);
    },
    fetchRcsCampaigns({ commit, state }, query) {
      return this._vm.$api.rcs.campaigns.fetch(query);
    },
    storeRcsMessages({ commit, state }, payload) {
      return this._vm.$api.rcs.messages.store(payload);
    },
    fetchRcsKeywords({ commit, state }, query) {
      return this._vm.$api.rcs.keywords.fetch(query);
    },
    storeRcsKeyword({ commit, state }, payload) {
      return this._vm.$api.rcs.keywords.store(payload);
    },
  },
};
