const routes = [
  {
    name: 'ContactsList',
    path: '/',
    component: () => (window.$rebranding.active ? import('../views/rebranding/contacts/List.vue') : import('../views/contacts/List.vue')),
  },
  {
    name: 'ContactDetail',
    path: 'detail',
    component: () => import('../views/rebranding/contacts/Detail.vue'),
  },
  {
    name: 'ContactsGroups',
    path: 'groups',
    component: () => (window.$rebranding.active ? import('../views/rebranding/contacts/Groups.vue') : import('../views/contacts/Groups.vue')),
  },
  {
    name: 'ImportContact',
    path: 'import',
    component: () => (window.$rebranding.active ? import('../views/rebranding/contacts/Import.vue') : import('../views/contacts/Import.vue')),
  },
  {
    name: 'GroupContacts',
    path: 'groups/:id',
    component: () => import('../views/contacts/GroupContacts.vue'),
  },
];

export default routes;
