import axios from '@/plugins/axios';

class AccountService {
  switchAccount(id) {
    this.id = id;
    return axios().post(`accounts/summary/${id}`).then((response) => {
      if (response.data.id) {
        localStorage.setItem('current-account', response.data.id);
      }
      return response.data;
    });
  }

  getInfo() {
    this.a = 'a';
    return axios().get('accounts/info').then((response) => response.data);
  }

  getAccount(accountId) {
    this.a = 'a';
    return axios().get(`accounts/${accountId}`).then((response) => response.data);
  }

  getAccounts() {
    this.a = 'a';
    return axios().get('accounts').then((response) => {
      if (!localStorage.getItem('current-account')) {
        localStorage.setItem('current-account', response.data[0].id);

        // store.state.account.name = response.data[0].name;
        // store.state.account.id = response.data[0].id;
      }
      return response.data;
    });
  }

  createAccount(data) {
    this.data = data;
    return axios().post('accounts', this.data).then((response) => response.data);
  }

  invite(data) {
    this.data = data;
    return axios().post('users/invite', this.data).then((response) => response.data);
  }
}

export default new AccountService();
