export default {
  state: {
    list: [],
  },
  mutations: {
  },
  actions: {
    fetchUsers({ commit, state }, query) {
      return this._vm.$api.users.fetchUsers(query);
    },
    storeUser({ commit, state }, payload) {
      return this._vm.$api.users.storeUser(payload);
    },
    updateUser({ commit, state }, payload) {
      return this._vm.$api.users.updateUser(payload.id, payload);
    },
    destroyUser({ commit, state }, userId) {
      return this._vm.$api.users.destroyUser(userId);
    },
  },
};
