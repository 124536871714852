const draggable = {
  update(el, binding, vnode, prevVnode) {
    console.log('draggable.update', el, binding, vnode, prevVnode);

    const rect = el.getBoundingClientRect();

    binding.isMove = false;

    el.style.removeProperty('position');
    el.style.removeProperty('left');
    el.style.removeProperty('top');

    binding.xel = rect.left;
    binding.yel = rect.top;
  },
  bind(el, binding, vnode, prevVnode) {
    // Verifica se esta se movendo
    binding.isMove = false;

    // Pega as coordenadas do mouse
    let x = 0;
    let y = 0;

    // ultima posição do elemento
    binding.xel = 0;
    binding.yel = 0;

    const w = window;
    const d = document;

    const rect = el.getBoundingClientRect();

    binding.xel = rect.left;
    binding.yel = rect.top;

    console.log('draggable', el);

    el.style.position = 'fixed';

    const refreshPosition = (e) => {
      const source = e.touches && e.touches && e.touches[0] ? e.touches[0] : e;

      x = source.pageX;
      y = source.pageY;
    };

    const mouseDownOrTouchStart = (e) => {
      binding.isMove = true;

      el.classList.toggle('isMoving', true);

      refreshPosition(e);

      binding.xel = x - el.offsetLeft;
      binding.yel = y - el.offsetTop;
    };

    el.addEventListener('mousedown', mouseDownOrTouchStart, { passive: false });
    el.addEventListener('touchstart', mouseDownOrTouchStart, { passive: false });

    const mouseMoveOrTouchMove = (e) => {
      if (!binding.isMove) return;

      e.preventDefault();

      refreshPosition(e);

      el.style.left = `${x - binding.xel}px`;
      el.style.top = `${y - binding.yel}px`;
    };

    d.addEventListener('mousemove', mouseMoveOrTouchMove, { passive: false });
    d.addEventListener('touchmove', mouseMoveOrTouchMove, { passive: false });

    const mouseUpOrTouchEnd = () => {
      binding.isMove = false;

      el.classList.toggle('isMoving', false);
    };

    d.addEventListener('mouseup', mouseUpOrTouchEnd, { passive: false });
    d.addEventListener('touchend', mouseUpOrTouchEnd, { passive: false });
  },
};

export default draggable;
