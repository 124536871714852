export default ($axios) => ({
  fetch(query) {
    return $axios.get('/rcs/messages', { params: query }).then((response) => response.data);
  },
  export(input) {
    return $axios.post('/rcs/reports', input).then((response) => response.data);
  },
  find(id) {
    return $axios.get(`/rcs/messages/${id}`).then((response) => response.data);
  },
  store(payload) {
    return $axios.post('/rcs/messages', payload).then((response) => response.data);
  },
  insights(input) {
    return $axios.post('/rcs/messages/insights', input).then((response) => response.data);
  },
});
