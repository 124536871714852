export default ($axios) => ({
  fetchUsers(query) {
    return $axios.get('/users', { params: query }).then((response) => response.data);
  },
  fetchUser(userId) {
    return $axios.get(`/users/${userId}`).then((response) => response.data);
  },
  storeUser(payload) {
    return $axios.post('/users/invite', payload).then((response) => response.data);
  },
  updateUser(userId, payload) {
    return $axios.put(`/users/${userId}`, payload).then((response) => response.data);
  },
  destroyUser(userId) {
    return $axios.delete(`/users/${userId}`).then((response) => response.data);
  },
});
