import axios from '@/plugins/axios';

class SmsService {
  getMessages(data) {
    this.data = data;
    return axios().get('sms/messages', { params: this.data }).then((response) => response.data);
  }

  getMessage(id) {
    this.id = id;
    return axios().get(`sms/messages/${id}`);
  }

  getSenders(data) {
    this.data = data;
    return axios().get('sms/senders', { params: this.data }).then((response) => response.data);
  }

  getProducts(data) {
    this.data = data;
    return axios().get('sms/products', { params: this.data }).then((response) => response.data);
  }

  sendSms(data) {
    this.data = data;
    return axios().post('sms/messages', this.data);
  }

  getCampaigns(data) {
    this.data = data;
    return axios().get('sms/campaigns', { params: this.data }).then((response) => response.data);
  }

  getUrls(data) {
    this.data = data;
    return axios().get('/urls', { params: this.data }).then((response) => response.data);
  }

  editCampaign(data) {
    this.data = data;
    return axios().put(`sms/campaigns/${data.id}`, this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteCampaign(campaignId) {
    this.campaignId = campaignId;
    return axios().delete(`sms/campaigns/${campaignId}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getInsights(data) {
    this.data = data;
    return axios().post('sms/insights', this.data).then((response) => response.data);
  }

  getUsers(data) {
    this.data = data;
    return axios().get('smpp/users', this.data).then((response) => response.data);
  }

  deleteUser(id) {
    this.id = id;
    return axios().delete(`smpp/users/${id}`).then((response) => response.data);
  }

  editUser(data) {
    this.data = data;
    return axios().put(`smpp/users/${data.id}`, this.data).then((response) => response.data);
  }

  deleteUsers(data) {
    this.data = data;
    return axios().delete('smpp/users', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  createSMPPUser(data) {
    this.data = data;
    return axios().post('smpp/users', this.data).then((response) => response.data);
  }

  exportSms(data) {
    this.data = data;
    return axios().post('sms/reports', this.data);
  }

  deleteTemplates(data) {
    this.data = data;
    return axios().delete('sms/templates', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  downlodExport(id) {
    this.id = id;
    return axios().get(`sms/reports/${id}/file`, { responseType: 'arraybuffer' });
  }

  getSmsPrice(id) {
    this.id = id;
    return axios().get(`sms/prices/${id}`);
  }

  putSmsPrice(data, id) {
    this.data = data;
    return axios().put(`sms/prices/${id}`, this.data);
  }

  reportsExport() {
    this.id = null;
    return axios().get('sms/reports');
  }

  filterSms(filter) {
    this.data = null;
    return axios().get(`sms/messages${filter}`);
  }

  chart(days) {
    this.a = 'a';
    return axios().get(`sms/messages/charts?days=${days}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  carriers(days) {
    this.a = 'a';
    return axios().get(`sms/messages/carriers?days=${days}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getTemplates(data) {
    this.data = data;
    return axios().get('sms/templates', { params: this.data }).then((response) => response.data);
  }

  getBlacklists(data) {
    this.data = data;
    return axios().get('blacklist', { params: this.data }).then((response) => response.data);
  }

  getWords(data) {
    this.data = data;
    return axios().get('/words', { params: this.data }).then((response) => response.data);
  }

  destroyBlacklist(id) {
    this.id = id;
    return axios().delete(`/blacklist/${id}`).then((response) => response.data);
  }

  createTemplate(data) {
    this.data = data;
    return axios().post('sms/templates', this.data).then((response) => response.data);
  }

  createBlackList(data) {
    this.data = data;
    return axios().post('/blacklist', this.data).then((response) => response.data);
  }

  createKeywords(data) {
    this.data = data;
    return axios().post('/keywords', this.data).then((response) => response.data);
  }

  createWordApprovals(data) {
    this.data = data;
    return axios().post('/words/approvals', this.data).then((response) => response.data);
  }

  createUrlApprovals(data) {
    this.data = data;
    return axios().post('/urls/approvals', this.data).then((response) => response.data);
  }

  editWordApprovals(data) {
    this.data = data;
    return axios().put('/words/approvals', this.data).then((response) => response.data);
  }

  editWords(data) {
    this.data = data;
    return axios().put(`/words/${data.id}`, this.data).then((response) => response.data);
  }

  editUrls(data) {
    this.data = data;
    return axios().put(`/urls/${data.id}`, this.data).then((response) => response.data);
  }

  removeWord(id) {
    this.data = id;
    return axios().delete(`/words/${id}`, this.id).then((response) => response.data);
  }

  removeUrl(id) {
    this.data = id;
    return axios().delete(`/urls/${id}`, this.id).then((response) => response.data);
  }

  wordApprove(id) {
    this.data = id;
    return axios().post(`/words/approvals/${id}/approve`, this.data).then((response) => response.data);
  }

  wordReprove(id) {
    this.data = id;
    return axios().post(`/words/approvals/${id}/reject`, this.data).then((response) => response.data);
  }

  urlApprove(id) {
    this.data = id;
    return axios().post(`/urls/approvals/${id}/approve`, this.data).then((response) => response.data);
  }

  urlReprove(id) {
    this.data = id;
    return axios().post(`/urls/approvals/${id}/reject`, this.data).then((response) => response.data);
  }

  getWordApprovals(data) {
    this.data = data;
    return axios().get('/words/approvals', { params: this.data }).then((response) => response.data);
  }

  getUrlApprovals(data) {
    this.data = data;
    return axios().get('/urls/approvals', { params: this.data }).then((response) => response.data);
  }

  deleteKeyword(id) {
    this.id = id;
    return axios().delete(`/keywords/${id}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteMultipleKeywords(data) {
    this.data = data;
    return axios().delete('/keywords', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteKeylist(data, page) {
    this.data = data;
    return axios().get(`/keywords?page=${page}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteKeylists(data) {
    this.data = data;
    return axios().delete('/keywords', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  editKeyList(data) {
    this.data = data;
    return axios().put(`/keywords/${data.id}`, this.data).then((response) => response.data);
  }

  getKeylist(data, page) {
    this.data = data;
    return axios().get(`/keywords?page=${page}`, { params: this.data }).then((response) => response.data);
  }

  deleteBlackList(id) {
    this.id = id;
    return axios().delete(`/blacklist/${id}`).then((response) => response.data);
  }

  fetchOptouts(data) {
    this.data = data;
    return axios().get('/optouts', { params: this.data }).then((response) => response.data);
  }

  createOptouts(data) {
    this.data = data;
    return axios().post('/optouts', this.data).then((response) => response.data);
  }

  deleteOptout(id) {
    this.id = id;
    return axios().delete(`/optouts/${id}`).then((response) => response.data);
  }

  deleteAllOptouts(data) {
    this.data = data;
    return axios().delete('/optouts', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  editOptouts(data) {
    this.data = data;
    return axios().put(`/optouts/${data.id}`, this.data).then((response) => response.data);
  }

  editBlackList(data) {
    data = JSON.parse(JSON.stringify(data));

    const redata = {};

    Object.keys(data).forEach((key) => {
      if (!(data[key] === null || data[key] === '' || data[key] === undefined)) {
        redata[key] = data[key];
      }
    });

    this.data = redata;
    return axios().put(`/blacklist/${data.id}`, this.data).then((response) => response.data);
  }

  deleteBlacklists(data) {
    this.data = data;
    return axios().delete('/blacklist', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  editTemplate(data) {
    this.data = data;
    return axios().put(`sms/templates/${data.id}`, this.data).then((response) => response.data);
  }

  deleteTemplate(id) {
    this.id = id;
    return axios().delete(`sms/templates/${id}`).then((response) => response.data);
  }

  getAvailableShortCodes(data) {
    this.data = data;
    return axios().get('sms/short_codes/available', { params: this.data }).then((response) => response.data);
  }

  getShortCodes(id) {
    this.id = id;
    return axios().get(`sms/short_codes/${id}`).then((response) => response.data);
  }

  getAccountShortCodes(data) {
    this.data = data;
    return axios().get('sms/short_codes', { params: data }).then((response) => response.data);
  }

  getAccountShortCode(id) {
    this.id = id;
    return axios().get(`sms/short_codes/${id}`).then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  updateAccountShortCode(form) {
    this.form = form;
    return axios().put(`sms/short_codes/${form.data.id}`, this.form).then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }
}

export default new SmsService();
