const modalDirective = {
  bind: (el, binding, vnode) => {
    el.addEventListener('click', () => {
      if (binding.value instanceof Object) {
        vnode.context.$root.$emit('show.modal', binding.value.target, binding.value.data);
      } else {
        vnode.context.$root.$emit('show.modal', binding.value);
      }
    });
  },
};

export default modalDirective;
