export default ($axios) => ({
  loginWithGoogle(data) {
    return $axios.post('/auth/login/google', data).then((response) => {
      localStorage.setItem('yup_chat_token', JSON.stringify(response.data.token));
      return response.data;
    });
  },
  loginWithFacebook(data) {
    return $axios.post('/auth/login/facebook', data).then((response) => {
      localStorage.setItem('yup_chat_token', JSON.stringify(response.data.token));
      return response.data;
    });
  },
});
