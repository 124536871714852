export default [
  {
    name: 'AdminPlansIndex',
    path: 'plans',
    component: () => import('../../views/admin/plans/Index.vue'),
  },
  {
    name: 'AdminPlansCreate',
    path: 'plans/create',
    component: () => import('../../views/admin/plans/Create.vue'),
  },
  {
    name: 'AdminPlanEdit',
    path: 'plans/:planId/edit',
    component: () => import('../../views/admin/plans/Edit.vue'),
  },
];
