import Customers from './customers';
import Plans from './plans';
import Product from './products';
import Invoices from './invoices.js';
import Whitelabel from './whitelabel.js';
import SMSMessages from './sms/messages.js';
import RCSMessages from './rcs/messages.js';

export default ($axios) => ({
  customers: Customers($axios),
  plans: Plans($axios),
  products: Product($axios),
  invoices: Invoices($axios),
  whitelabel: Whitelabel($axios),
  sms: {
    messages: SMSMessages($axios),
  },
  rcs: {
    messages: RCSMessages($axios),
  },
});
