export default {
  state: {
    list: [],
  },
  mutations: {
  },
  actions: {
    fetchLogs({ commit, state }, query) {
      return this._vm.$api.logs.fetchLogs(query);
    },
  },
};
