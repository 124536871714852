<template>
  <div id="app">
    <router-view />

    <!--<template v-if="favicon === 'algartelecom'">
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://algartelecom.com.br/assets-tic/favicon-32x32.png?v=fcac2bffd1094696ed1dfe63bdbc1c2e"
      />
    </template>-->
    <!-- <template v-else-if="favicon === 'sample'">
      <link rel="icon" type="image/png" sizes="32x32" :href="require('@/assets/favicons/sample-32x32.png')">
    </template> -->
  </div>
</template>
<script>
// import vueCustomScrollbar from 'vue-custom-scrollbar';
import 'vue-custom-scrollbar/dist/vueScrollbar.css';

export default {
  watch: {
    $route() {
      this.$live.refreshTitle();
    },
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchWhitelabel', this.form).then((response) => {
      if (response.name) {
        document.title = response.name;
      }
      if (response.name === 'Algar') {
        const favicon = document.getElementById('favicon');
        favicon.href = '/img/icons/algartelecom-32x32.png';
      } else if (response.name === 'Mercafacil') {
        const favicon = document.getElementById('favicon');
        favicon.href = '/img/icons/mercafacil-32x32.png';
      } else if (response.name === 'Kankei') {
        const favicon = document.getElementById('favicon');
        favicon.href = '/img/icons/kankei-32x32.png';
      } else if (response.name === 'M2C DIGITAL') {
        const favicon = document.getElementById('favicon');
        favicon.href = '/img/icons/m2c.png';
      }
      document.documentElement.style.setProperty(
        '--clr-yup-purple',
        response.color,
      );
    });
  },
  data() {
    return {
      favicon: null,
    };
  },
  methods: {
    isMobile() {
      const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return regex.test(navigator.userAgent);
    },
    refreshIcon() {
      if (this.favicon !== null) {
        document.querySelectorAll('head > link[rel="icon"]').forEach((el) => {
          el.disabled = true;
        });
      }
    },
  },
  mounted() {
    const hostname = window.location.hostname;

    console.log('rebranding', this.$rebranding.active, hostname);

    switch (hostname) {
      case 'smsycorporativo.algartelecom.com.br':
        this.favicon = 'algartelecom';
        break;
      case 'localhost':
        this.favicon = 'algartelecom';
        break;
      default:
        console.info('Default favicon');
    }

    this.refreshIcon();
    if (this.isMobile()) {
      // console.log('Mobile device detected');
      localStorage.setItem('mobile', 'mobile');
    } else {
      // console.log('Desktop device detected');
      localStorage.setItem('mobile', 'desktop');
    }

    // const rebranding = localStorage.getItem('rebrading');
    // if (rebranding === null || rebranding === undefined || rebranding === '0') {
    //   document.body.classList.remove('rebranding');
    // } else {
    //   document.body.classList.add('rebranding');
    // }
  },
};
</script>
<style lang="scss">
@import 'assets/scss/app';
@import '../node_modules/c3/c3.min.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
@import '/assets/css/style.css';

:root {
  --clr-light-2: #697077;
  --form-border: #e0e3e6;
}

.testing-container p {
  color: #fff !important;
}

.vs__open-indicator,
span.vs__open-indicator,
button.vs__deselect span,
.vs__dropdown-toggle button.vs__clear span {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  color: var(--gray-font-color-2);
}

.config_dot {
  position: relative;
  z-index: 5;
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  transform: scale(2);
  color: var(--gray-font-color-5);
}

.thin-icon *,
.thin-icon {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.fill-icon *,
.fill-icon {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.btn {
  border-radius: 8px;
  &.btn-primary {
    background-color: var(--clr-yup-purple) !important;
    border-color: var(--clr-yup-purple) !important;
    color: #fff;
    transition: 0.5s;
    font-weight: 400;

    &:hover,
    &:focus {
      // filter: saturate(0.7);
      // filter: brightness(0.7);
      color: #fff;
      box-shadow: unset !important;
    }
  }

  &.btn-outline-primary {
    border-color: var(--clr-yup-purple) !important;
    background-color: var(--clr-light);
    color: var(--clr-yup-purple) !important;
    transition: 0.5s;
    font-weight: 400 !important;

    &.bg-transparent {
      border-radius: 0;
      box-shadow: 0 2px 0 0 transparent !important;
    }

    &:hover,
    &:focus {
      // filter: saturate(0.7);
      // filter: brightness(0.7);
      background-color: var(--clr-yup-purple) !important;
      color: #fff !important;
      box-shadow: unset !important;
    }
    &:hover.bg-transparent, &:focus.bg-transparent {
      background-color: transparent !important;
      color: var(--clr-yup-purple) !important;
      box-shadow: 0 2px 0 0 var(--clr-yup-purple) !important;
    }
  }

  &.btn-outline-danger {
    border-color: #AD1519 !important;
    background-color: var(--clr-light);
    color: #AD1519 !important;
    transition: 0.5s;
    font-weight: 400 !important;

    &.bg-transparent {
      border-radius: 0;
      box-shadow: 0 2px 0 0 transparent !important;
    }

    &:hover,
    &:focus {
      // filter: saturate(0.7);
      // filter: brightness(0.7);
      background-color: #AD1519 !important;
      color: #fff !important;
      box-shadow: unset !important;
    }
    &:hover.bg-transparent, &:focus.bg-transparent {
      background-color: transparent !important;
      color: #AD1519 !important;
      box-shadow: 0 2px 0 0 #AD1519 !important;
    }
  }

  &.btn-outline-secondary {
    background-color: transparent !important;
    color: #0e1421 !important;

    &:hover {
      background-color: #0e1421 !important;
      color: #fff !important;
    }
  }
}

.darkmode {
  .btn {
    color: #fff;
  }
}

.darkmode .btn-outline-primary {
  background-color: var(--background-2);
  color: var(--foreground);
}

.dropdown-badge .dropdown-toggle::after {
  content: unset !important;
}

.rebranding .dropdown-menu {
  background-color: var(--background-2);
  box-shadow: 0px 2px 4px #00000029;
}

.rebranding.darkmode
  .dropdown-menu:not(.rebranding.darkmode .dropdown-menu .dropdown-item) {
  color: #fff;
}

/* Alert */
.rebranding.darkmode .alert.alert-primary {
  background-color: #031633;
  border-color: #084298;
  color: #6ea8fe;

  pre {
    color: inherit;
  }
}

.rebranding .vue-tel-input {
  border-color: var(--form-border);
  border: unset !important;
}

.rebranding .vti__dropdown {
  background-color: var(--form-input) !important;
  border-color: var(--form-border);
  border-width: 1px;
  border-style: solid;
  border-right: transparent;
}

.rebranding.darkmode .badge.badge-warning {
  background-color: #332701;
  border-color: #664d03;
  color: #ffc100;
}

/* Custom alert */
.rebranding .badge.badge-urgent {
  background-color: var(--urgent-bg) !important;
  border-color: var(--urgent-fg) !important;
  color: var(--urgent-fg) !important;
}

.rebranding .badge.badge-normal,
.rebranding .badge.badge-medium {
  background-color: var(--normal-bg) !important;
  border-color: var(--normal-fg) !important;
  color: var(--normal-fg) !important;
}

.rebranding .badge.badge-high {
  background-color: var(--high-bg) !important;
  border-color: var(--high-fg) !important;
  color: var(--high-fg) !important;
}

.rebranding .badge.badge-purple {
  background-color: var(--clr-yup-purple) !important;
  border-color: var(--clr-yup-purple) !important;
  color: #fff !important;
}

.rebranding .badge.badge-sent,
.rebranding .badge.badge-low {
  background-color: var(--sent-bg) !important;
  border-color: var(--sent-fg) !important;
  color: var(--sent-fg) !important;
}

.rebranding .badge.badge-delivered {
  background-color: var(--delivered-bg) !important;
  border-color: var(--delivered-fg) !important;
  color: var(--delivered-fg) !important;
}

.rebranding .badge.badge-scheduled {
  background-color: var(--scheduled-bg) !important;
  border-color: var(--scheduled-fg) !important;
  color: var(--scheduled-fg) !important;
}

.rebranding .badge.badge-analysis {
  background-color: var(--analysis-bg) !important;
  border-color: var(--analysis-fg) !important;
  color: var(--analysis-fg) !important;
}

.rebranding .badge.badge-rejected {
  background-color: var(--rejected-bg) !important;
  border-color: var(--rejected-fg) !important;
  color: var(--rejected-fg) !important;
}

.rebranding .badge.badge-unavailable {
  background-color: var(--unavailable-bg) !important;
  border-color: var(--unavailable-fg) !important;
  color: var(--unavailable-fg) !important;
}

.rebranding .badge.badge-response {
  background-color: var(--response-bg) !important;
  border-color: var(--response-fg) !important;
  color: var(--response-fg) !important;
}

.rebranding .badge.badge-readed {
  background-color: var(--readed-bg) !important;
  border-color: var(--readed-fg) !important;
  color: var(--readed-fg) !important;
}

.rebranding .badge.badge-optional {
  background-color: var(--pending-bg) !important;
  border-color: var(--pending-fg) !important;
  color: var(--pending-fg) !important;
}

.darkmode.rebranding .badge.badge-optional {
  background-color: var(--pending-bg) !important;
  border-color: var(--pending-fg) !important;
  color: var(--pending-fg) !important;
}

/* Heroicon */
.heroicon {
  width: 1.25rem;
  height: 1.25rem;
}

/*/Heroicon */

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

#app {
  min-height: 100vh;
}

.ps-container {
  .ps__rail-x,
  .ps__rail-y {
    background-color: transparent !important;

    &.ps--clicking {
      .ps__thumb-x,
      .ps__thumb-y {
        background-color: rgba(0, 0, 0, 0.4) !important;
      }
    }
  }

  .ps__thumb-y {
    width: 6px !important;
    right: 0 !important;
  }

  .ps__thumb-x {
    height: 6px !important;
  }

  .ps__thumb-x,
  .ps__thumb-y {
    background-color: rgba(0, 0, 0, 0.2) !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4) !important;
    }
  }
}

body.darkmode {
  .ps-container {
    .ps__rail-x,
    .ps__rail-y {
      &.ps--clicking {
        .ps__thumb-x,
        .ps__thumb-y {
          background-color: rgba(255, 255, 255, 0.4) !important;
        }
      }
    }

    .ps__thumb-x,
    .ps__thumb-y {
      background-color: rgba(255, 255, 255, 0.2) !important;

      &:hover {
        background-color: rgba(255, 255, 255, 0.4) !important;
      }
    }
  }
}

/* .h1, .h2, .h3, .h4, .h5, .h6, .nav.metismenu, body, h1, h2, h3, h4, h5, h6 {
  font-family: Maven Pro;
} */

.swal2-container {
  z-index: 6000 !important;
}

.swal2-popup.swal2-toast {
  background-color: var(--background-2) !important;
}

.darkmode .swal2-popup.swal2-toast {
  box-shadow: 0px 2px 4px #00000029;
}

.toaster {
  z-index: 6001 !important;
}

html,
body {
  min-width: 320px;
}

body {
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #cacccc;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #cacccc;
}

.card.card-expand .card-body {
  height: calc(100vh - 55px);
  overflow: auto;
}

.content-wrapper {
  min-width: 320px;
  display: block !important;
  padding-top: 3rem;

  @media (min-width: 993px) {
    // padding-left: 5rem;
    padding-left: 61px;
    padding-top: 63px;

    .page-content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.loading-wrapper .aside-overlay-absolute,
.aside-overlay-fixed,
.modal-backdrop,
.qt-block-ui {
  z-index: 3000;
}

.qt-block-ui-rebrand {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffffd6;
  z-index: 2000;
  text-align: center;
}

.qt-block-ui-rebrand img {
  margin-top: 15%;
}

.darkmode .qt-block-ui-rebrand,
.darkmode .loading-wrapper {
  background: #00000029;
}

.loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffffd6;
  z-index: 2000;
  text-align: center;
  top: 0;
  left: 0;
  display: flex;
}

.modal {
  z-index: 3001;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

.btn,
.dropdown-action,
.noUi-target,
.noUi-target *,
a:link.btn,
select.form-control {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

select.form-control {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAEAQMAAACEHZz0AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAABRJREFUCNdjOMzAkMbAYMPAIMEAAAz5AX6NcAF3AAAAAElFTkSuQmCC')
    right center no-repeat;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  opacity: 0.8;
  color: #f9f9f9;
  border-radius: 3px;
  padding: 6px 11px 5px;
}

.tooltip .tooltip-arrow {
  opacity: 0.8;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}

.tooltip[x-placement^='top'] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}

.tooltip[x-placement^='bottom'] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='right'] {
  margin-left: 5px;
  margin-right: -200px;
}

.tooltip[x-placement^='right'] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^='left'] {
  margin-right: 5px;
}

.tooltip[x-placement^='left'] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.static {
  position: static !important;
}

.badge.badge-warning {
  background: #ffc100;
  color: #0e1421;
}

@mixin disable-touch {
  .disable-touch #top-menu {
    .toggle-btn {
      touch-action: auto !important;
    }

    & ~ div {
      transition: none !important;
    }
  }

  .disable-touch,
  .disable-touch > body {
    touch-action: none !important;
    overflow: hidden !important;
  }

  .disable-touch #sidebarMainMenu,
  .disable-touch [id^='sidebarSubMenu'] {
    touch-action: auto !important;
  }

  .disable-touch .parent-sidebar ~ div {
    position: fixed !important;
  }

  .desktop-wrapper .content {
    width: 100% !important;
    padding-left: 0 !important;
  }
}

.tooltip {
  opacity: 1 !important;
}

#dark-mode-toggle {
  /* position: fixed;
  bottom: 15px;
  left: 15px; */
  width: 32px;
  transform: scale(1);
  transition: 0.5s;
  padding: 0;
}

#auth #dark-mode-toggle {
  position: fixed;
  bottom: 15px;
  left: 15px;
}

@media (max-width: 992px) {
  @include disable-touch;
}

.whats-container {
  background: #efeae2 url('/assets/img/chat-bg.png');
  padding: 0.5rem;
  border-radius: 0.3rem;

  &,
  * {
    // pointer-events: none;
  }
}

.whats-container,
.whatsapp-message {
  .btn-link-preview {
    background: rgb(255, 255, 255);
    border-radius: 6px;
    box-shadow: 0 0.125rem 0.5rem rgba(12, 12, 13, 0.1);
    border: none;
    width: 100% !important;
    padding: 10px;
    margin-top: 10px;
    padding: 0.7rem;
    margin-top: 0.4rem;
  }
}

.whats-preview {
  background: rgb(255, 255, 255);
  border-radius: 0.3rem;
  position: relative;
  padding: 1.72rem;
  width: 100%;
  min-height: 11rem;
  box-shadow: 0 0.125rem 0.5rem rgba(12, 12, 13, 0.1);
}

.whats-preview img {
  width: 100%;
  object-fit: cover;
  height: 12.5rem;
  border-radius: 0.3rem;
}

.whats-preview-header {
  color: #000;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 0;
}

.whats-preview-footer {
  color: #000;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 0;
  margin-top: 0;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

body.rebranding {
  &.darkmode .apexcharts-legend-text,
  &.darkmode .apexcharts-text tspan {
    color: var(--foreground) !important;
    fill: var(--foreground) !important;
  }

  /* #app::after {
    content: '';
    pointer-events: none;
    background: url(assets/background-1.svg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: -320px;
    right: -430px;
    max-width: 1735px;
    max-height: 1324px;
    z-index: -1;
  } */
}

.form-control[readonly] {
  border-style: solid !important;
}

.btn-yup-purple {
  background-color: var(--clr-yup-purple) !important;
}

.btn-yup-purple:hover,
.btn-yup-purple:focus {
  background-color: var(--clr-yup-purple) !important;
  filter: saturate(0.7) brightness(0.7) !important;
}

.btn-yup-purple-outline:hover,
.btn-yup-purple-outline:focus {
  background-color: var(--clr-yup-purple) !important;
  filter: saturate(0.7) brightness(0.7) !important;
}

.alert-info-primary {
  color: var(--clr-yup-purple) !important;
}

.tooltip.primary-tooltip .tooltip-inner {
  color: var(--clr-yup-purple) !important;
}

.h-100 {
  height: 100% !important;
}
</style>
