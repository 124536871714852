<template>
  <div v-if="lastPage > 1" class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-5"></div>
      <div class="col-sm-12 col-md-7">
        <div
          class="dataTables_paginate paging_simple_numbers"
          id="bs4-table_paginate"
        >
          <ul class="pagination">
            <li
              class="paginate_button page-item previous"
              :class="{ disabled: currentPage == 1 }"
              id="bs4-table_previous"
            >
              <a
                href="javascript:void(0)"
                aria-controls="bs4-table"
                data-dt-idx="0"
                tabindex="0"
                class="page-link"
                @click="previousPage"
                >{{ $t('generic-str.previous') }}</a
              >
            </li>
            <li
              v-for="(page, i) in pages"
              :key="i"
              :class="{ active: currentPage == page }"
              class="paginate_button page-item"
            >
              <div v-if="page == '...'" class="page-link">
                {{ page }}
              </div>
              <a
                v-else
                href="javascript:void(0)"
                aria-controls="bs4-table"
                data-dt-idx="1"
                tabindex="0"
                class="page-link"
                @click="changePage(page)"
                >{{ page }}</a
              >
            </li>
            <li
              class="paginate_button page-item next"
              id="bs4-table_next"
              :class="{ disabled: currentPage == lastPage }"
            >
              <a
                href="javascript:void(0)"
                aria-controls="bs4-table"
                data-dt-idx="7"
                tabindex="0"
                class="page-link"
                @click="nextPage"
                >{{ $t('generic-str.next') }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['lastPage'],
  data() {
    return {
      currentPage: 1,
    };
  },
  watch: {
    lastPage: {
      handler(newValue, oldValue) {
        this.currentPage = 1;
        console.log('lastPage', { newValue, oldValue });
      },
    },
  },
  computed: {
    startPage() {
      if (this.currentPage >= 4 && this.currentPage < this.lastPage) {
        return this.currentPage - 1;
      }
      return Math.max(1, this.currentPage - 2);

      /* if (this.currentPage === this.lastPage) {
        return this.lastPage - 4;
      }

      return this.currentPage >= 3 ? this.currentPage - 2 : 1; */
    },
    endPage() {
      if (this.currentPage < this.lastPage - 2 && this.currentPage > 1) {
        return this.currentPage + 1;
      }
      return Math.min(this.lastPage, this.currentPage + 2);

      /* if (this.currentPage === this.lastPage || this.lastPage <= 6) {
        return this.lastPage;
      }
      return this.currentPage <= this.lastPage - 3 ? this.currentPage + 3 : this.lastPage; */
    },
    pages() {
      const range = [];

      if (this.currentPage >= 4) {
        range.push(1);
        if (this.lastPage > 4) range.push('...');
      }

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push(i);
      }

      if (this.currentPage < this.lastPage - 2) {
        if (this.lastPage > 4) range.push('...');
        range.push(this.lastPage);
      }

      return range;
    },
  },
  methods: {
    previousPage() {
      this.changePage((this.currentPage -= 1));
    },
    nextPage() {
      this.changePage((this.currentPage += 1));
    },
    changePage(page) {
      this.currentPage = page;
      this.$emit('change', page);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}
.dataTables_paginate {
  margin: 4px 0 15px !important;
}
</style>
