<template>
  <div class="content">
    <PageHeader :title="$t('generic-str.queue')" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-body">
          <p class="mb-0">
            Este painel fornece visualização das mensagens na fila da plataforma da Yup Chat.
            Existem limites para a rapidez com que as mensagens podem ser enviadas para a rede da
            operadora. Para manter a conformidade, a Yup Chat enfileira automaticamente suas
            mensagens e as entrega às operadoras a taxas apropriadas. Use este painel para
            determinar se está ocorrendo tudo como o esperado, para diagnosticar atrasos.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <sms-queue-card />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import SmsQueueCard from '@/components/sms/QueueCard.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    SmsQueueCard,
  },
  data() {
    return {
      messages: {
        labels: ['10/04', '11/04', '12/04', '13/04', '14/04', '15/04', '16/04'],
        datasets: [
          {
            label: 'Envios',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            lineTension: 0.1,
          },
          {
            label: 'Respostas',
            data: [10, 9, 0, 1, 6, 5, 0],
            fill: false,
            borderColor: 'rgb(0, 0, 0)',
            lineTension: 0.1,
          },
        ],
      },
    };
  },
};
</script>
