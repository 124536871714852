<template>
  <div class="card">
    <h5 class="card-header">{{$t('properties.title')}}</h5>
    <div class="card-body block-el">
      <form v-if="loaded" action="#" class="form-horizontal">
        <div class="row">
          <div class="col-md-6">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-right text-left-m col-md-3 weight-500">ID</label>
                <label class="control-label text-left col-md-9">{{ $route.params.id }}</label>
              </div>
              <div class="form-group row">
                <label
                  class="control-label text-right text-left-m col-md-3 weight-500"
                >{{$t('generic-str.sent-in')}}</label>
                <label class="control-label text-left col-md-9">{{ message.sent_at | formatDate24}}</label>
              </div>
              <div class="form-group row">
                <label
                  class="control-label text-right text-left-m col-md-3 weight-500"
                >{{$t('generic-str.lbl-sender')}}</label>
                <label class="control-label text-left col-md-9">{{ message.from }}</label>
              </div>
              <div class="form-group row">
                <label
                  class="control-label text-right text-left-m col-md-3 weight-500"
                >{{$t('generic-str.lbl-recipient')}}</label>
                <label class="control-label text-left col-md-9">{{ message.to }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-body">
              <div class="form-group row">
                <label
                  class="control-label text-right text-left-m col-md-3 weight-500"
                >{{$t('generic-str.type')}}</label>
                <label class="control-label text-left col-md-9">
                  <template v-if="message.direction == 'outbound'">{{$t('generic-str.send-2')}}</template>
                  <template v-else-if="message.direction == 'inbound'">{{$tc('generic-str.status.lbl-response', 1)}}</template>
                </label>
              </div>
              <div class="form-group row">
                <label
                  class="control-label text-right text-left-m col-md-3 weight-500"
                >{{$t('generic-str.lbl-cost')}}</label>
                <label
                  class="control-label text-left col-md-9"
                >R${{ message.price || currency4D }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <hr>
            <div class="form-body">
              <div class="form-group ">
                <h3
                  class="control-label text-left text-left-m col-md-12 weight-500"
                >{{$t('generic-str.content')}}</h3>
                <div class="col-md-12" v-if="message.direction == 'inbound'">
                  {{message.content}}
                </div>
                <div class="col-md-12">
                  <div v-if="message.type == 'link'">
                    {{message.content.messageText}}
                    <hr>
                    <div style="margin:10px; float: left;" v-for="(btn, i) in message.content.suggestions" :key="i">
                      <button class="btn btn-primary left-animate" type="button">
                        {{btn.action.text}}
                      </button>
                    </div>
                  </div>
                  <div v-if="message.type == 'midia'">
                    <a :href="message.content.fileUrl" target="_blank">Clique para ver a mídia</a>
                  </div>
                  <div v-if="message.type == 'text'">
                    {{message.content.content}}
                  </div>
                  <div class="shadow-card rounded card-rich" style="width: 500px; overflow: hidden;" v-if="message.type == 'card'">
                    <div class="card-rich-cover relative">
                      <img :src="this.message.content.imageUrl" style="width: 500px;" id="card-rich-cover-tab"/>
                    </div>
                    <br>
                    <br>
                    <h2>
                      <span>{{this.message.content.messageText}}</span>
                    </h2>
                    <div style="clear:both;"></div>
                    <p>
                      <span>
                        {{this.message.content.messageDescription}}
                      </span>
                    </p>
                    <hr class="no-margin">
                    <div style="margin:10px; float: left;" v-for="(btn, i) in this.message.content.suggestions" :key="i">
                      <button class="btn btn-primary left-animate" type="button">
                        {{btn.reply.text}}
                      </button>
                    </div>
                  </div>
                  <div v-if="message.type == 'card-carousel'">
                    <div class="c-gallery">
                      <div class="c-gallery__img shadow-card rounded card-rich" style="width: 300px;height: 340px;" v-for="(rich, i) in message.content.images" :key="i">
                        <div class="card-rich-cover card-c-rich-cover relative">
                          <img :src="rich.uri"  :id="`card-rich-cover-${i}`">
                        </div>
                        <br>
                        <h3 style="display: block;margin-top: 49px;">
                          <span >
                            {{rich.title}}
                          </span>
                        </h3>
                        <p>
                          <span>
                            {{rich.description}}
                          </span>
                        </p>
                        <hr class="no-margin">
                        <div style="margin:10px; float: left;" v-for="(btn, i) in rich.suggestions" :key="i">
                          <button class="btn btn-primary left-animate" type="button">
                            {{btn.action.text}}
                          </button>
                        </div>
                        <div style="clear:both;"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div v-else class="qt-block-ui" />
    </div>
  </div>
</template>

<script>
import RCSService from '@/services/rcs.service';

export default {
  name: 'HistoryCard',
  data() {
    return {
      loaded: false,
      message: {},
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      RCSService.getMessage(this.$route.params.id).then(
        (response) => {
          this.loaded = true;
          console.log(response);
          this.message = response.data;
          this.message.content = JSON.parse(this.message.content);
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
.card-rich h2{
  padding: 10px;
}
.card-rich h3{
  padding: 10px;
}
.card-rich p{
  padding: 10px;
}
</style>
