export default [
  {
    name: 'Dashboard',
    path: 'live/dashboard',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/live/Dashboard.vue') : import('../views/live/Dashboard.vue')),
  },
  {
    name: 'LiveRebrading',
    path: 'live',
    // component: () => import('../views/live/Index.vue'),
    component: () => (
      window.$rebranding.active ? import('../views/rebranding/live/Index.vue') : import('../views/live/Index.vue')
    ),
    // children: [
    //   {
    //     name: 'Cliente',
    //     path: ':id([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
    //     component: () => import('../views/live/view.vue'),
    //   },
    //   {
    //     name: 'Histórico',
    //     path: ':id([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/history',
    //     component: () => import('../views/live/history.vue'),
    //   },
    // ],
  },
  {
    name: 'LiveViewRebrading',
    path: 'live/manage',
    component: () => (
      window.$rebranding.active ? import('../views/rebranding/live/IndexManager.vue') : import('../views/live/IndexManager.vue')
    ),
    meta: {
      role: 'admin,owner,sales',
      manage: true,
    },
  },
  {
    name: 'System',
    path: 'live/configs/system',
    component: () => (window.$rebranding.active ? import('../views/rebranding/live/configs/System.vue') : import('../views/live/configs/System.vue')),
  },
  {
    name: 'WidgetList',
    path: 'live/configs/widget',
    component: () => import('../views/rebranding/live/configs/WidgetList.vue'),
  },
  {
    name: 'Widget',
    path: 'live/configs/widget/config',
    component: () => (window.$rebranding.active ? import('../views/rebranding/live/configs/Widget.vue') : import('@/views/live/configs/Widget.vue')),
  },
  {
    name: 'Holidays',
    path: 'live/configs/holidays',
    component: () => import('../views/live/configs/Holidays/Index.vue'),
  },
  {
    name: 'CreateEditHoliday',
    path: 'live/configs/holidays/create',
    component: () => import('../views/live/configs/Holidays/Create.vue'),
  },
  {
    name: 'OpeningHours',
    path: 'live/configs/opening-hours',
    component: () => import('../views/live/configs/OpeningHours/Index.vue'),
  },
  {
    name: 'CreateEditOpeningHour',
    path: 'live/configs/opening-hours/create',
    component: () => import('../views/live/configs/OpeningHours/Create.vue'),
  },
  {
    name: 'Responses',
    path: 'live/configs/responses',
    component: () => (window.$rebranding.active ? import('../views/rebranding/live/configs/Responses.vue') : import('../views/live/configs/Responses.vue')),
  },
  {
    name: 'OmniBusinessReport',
    path: 'live/reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    // component: LiveUsage,
    component: () => import('../views/rebranding/live/reports/Index.vue'),
    children: [
      {
        name: 'OmniBusinessReportStats',
        path: 'stat',
        component: () => import('@/views/rebranding/live/reports/Stat.vue'),
      },
      {
        name: 'OmniBusinessReportHistory',
        path: 'history',
        component: () => import('@/views/rebranding/live/reports/History.vue'),
      },
      {
        name: 'OmniBusinessReportExport',
        path: 'exports',
        component: () => import('@/views/rebranding/live/reports/Export.vue'),
      },
    ],
  },
  {
    name: 'LiveUsage',
    path: 'live/usage',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    // component: LiveUsage,
    component: () => import('../views/rebranding/live/Usage.vue'),
    children: [
      {
        name: 'LiveReport',
        path: '',
        component: () => import('@/views/rebranding/live/Report.vue'),
      },
      {
        name: 'LiveStats',
        path: 'stats',
        component: () => import('@/views/rebranding/live/stats/Index.vue'),
      },
      {
        name: 'LiveHistoryTab',
        path: 'history',
        component: () => import('@/views/rebranding/live/history/History.vue'),
      },
    ],
  },
  {
    name: 'Tabulação',
    path: 'live/configs/tabulations',
    component: () => import('../views/live/configs/Tabulations.vue'),
  },
  {
    name: 'LiveBilling',
    path: 'live/billing',
    component: () => import('../views/live/Billing.vue'),
  },
  {
    name: 'OmniHistory',
    path: 'live/history',
    component: () => import('../views/live/History.vue'),
  },
  {
    name: 'OmniReport',
    path: 'live/report',
    component: () => import('../views/live/Report.vue'),
  },
  {
    name: 'OmniRoom',
    path: 'live/history/:roomId',
    component: () => import('../views/live/Room.vue'),
  },
];
