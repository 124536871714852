import { render, staticRenderFns } from "./EmojiIcon.vue?vue&type=template&id=2805fdff&scoped=true&"
import script from "./EmojiIcon.vue?vue&type=script&lang=js&"
export * from "./EmojiIcon.vue?vue&type=script&lang=js&"
import style0 from "./EmojiIcon.vue?vue&type=style&index=0&id=2805fdff&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2805fdff",
  null
  
)

export default component.exports