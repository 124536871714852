const routes = [
  {
    name: 'Reclame Aqui',
    path: '/',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/ra/Dashboard.vue'),
  },
  {
    name: 'Reclamações',
    path: 'all',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/ra/Ticket.vue'),
  },
  {
    name: 'Relatório',
    path: 'report',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/ra/Report.vue'),
  },
  {
    name: 'Detalhe',
    path: 'view-ticket/:id',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/ra/ViewTicket.vue'),
  },
];

export default routes;
