export default function (value) {
  let max = value.indexOf('?');

  if (max !== -1) value = value.substring(0, max);

  max = value.lastIndexOf('/');

  // console.log('live_serive_filters_basename', max, value);

  if (max === -1) return value;

  return value.substring(max + 1);
}
