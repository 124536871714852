import axios from '@/plugins/axios';

class MailService {
  sendMail(data) {
    this.data = data;
    return axios().post('/email/messages', this.data);
  }

  getMessages(data) {
    this.data = data;
    return axios().get('/email/messages', { params: this.data }).then((response) => response.data);
  }

  getMessage(id) {
    this.id = id;
    return axios().get(`email/messages/${id}`);
  }

  getProducts(data) {
    this.data = data;
    return axios().get('email/products', { params: this.data }).then((response) => response.data);
  }

  validateDomain(domain) {
    this.data = null;
    return axios().post(`email/domains/validate/${domain}`).then((response) => response.data);
  }

  getDomains(data) {
    this.data = data;
    return axios().get('/email/domains', { params: this.data }).then((response) => response.data);
  }

  getDomain(id) {
    this.data = id;
    return axios().get(`email/domains/${id}`, { params: this.data }).then((response) => response.data);
  }

  getInsights(data) {
    this.data = data;
    return axios().post('/email/insights', this.data).then((response) => response.data);
  }

  getEventInsights(data) {
    this.data = data;
    return axios().post('/email/insights/events', this.data).then((response) => response.data);
  }

  async deleteDomain(id) {
    this.data = id;
    return axios().delete(`email/domains/${id}`, { params: this.data }).then((response) => response.data);
  }

  exportOptout(data) {
    this.data = data;
    if (this.data) {
      this.data.channel = 'email';
    } else {
      this.data = { channel: 'email' };
    }
    return axios().post('/optouts/export', this.data, { responseType: 'blob' }).then((response) => response.data);
  }

  putDomains(data) {
    this.data = data;
    return axios().post('/email/domains', this.data).then((response) => response.data);
  }

  validateEmail(email) {
    this.data = email;
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    return re.test(email);
  }

  chart(days) {
    this.a = 'a';
    return axios().get(`/email/messages/charts?days=${days}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  createTemplate(data) {
    this.data = data;
    return axios().post('/email/templates', this.data).then((response) => response.data);
  }

  createLib(data) {
    this.data = data;
    return axios().post('/email/libs', this.data).then((response) => response.data);
  }

  editLib(data) {
    this.data = data;
    return axios().put(`/email/libs/${data.id}`, this.data).then((response) => response.data);
  }

  deleteTemplates(data) {
    this.data = data;
    return axios().delete('/email/templates', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  getTemplates(data) {
    this.data = data;
    return axios().get('/email/templates', { params: this.data }).then((response) => response.data);
  }

  getLibs(data, page) {
    this.data = data;
    return axios().get(`/email/libs?page=${page}`, { params: this.data }).then((response) => response.data);
  }

  deleteLib(id) {
    this.id = id;
    return axios().delete(`/email/libs/${id}`).then((response) => response.data);
  }

  editTemplate(data) {
    this.data = data;
    return axios().put(`/email/templates/${data.id}`, this.data).then((response) => response.data);
  }

  deleteTemplate(id) {
    this.id = id;
    return axios().delete(`/email/templates/${id}`).then((response) => response.data);
  }

  getCampaigns(data) {
    this.data = data;
    return axios().get('/email/campaigns', { params: this.data }).then((response) => response.data);
  }

  editCampaign(data) {
    this.data = data;
    return axios().put(`/email/campaigns/${data.id}`, this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteCampaign(campaignId) {
    this.campaignId = campaignId;
    return axios().delete(`email/campaigns/${campaignId}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  downlodExport(id) {
    this.id = id;
    return axios().get(`email/reports/${id}/file`, { responseType: 'blob' });
  }

  reportsExport() {
    this.id = null;
    return axios().get('email/reports');
  }

  exportMails(data) {
    this.data = data;
    return axios().post('/email/reports', this.data);
  }

  emailPreview(id) {
    this.id = id;
    return axios().get(`/email/${id}/preview`);
  }

  exportReport(data) {
    this.data = data;
    return axios().post('/email/reports/export', this.data, { responseType: 'blob' });
  }

  getEmailPrice(id) {
    this.id = id;
    return axios().get(`email/prices/${id}`);
  }

  putEmailPrice(data, id) {
    this.data = data;
    return axios().put(`email/prices/${id}`, this.data);
  }

  getBlacklists(data) {
    this.data = data;
    return axios().get('blacklist', { params: this.data }).then((response) => response.data);
  }

  getTrackingSettings(data) {
    this.data = data;
    return axios().get('email/subscription-tracking-settings', { params: this.data }).then((response) => response.data);
  }

  putTrackingSettings(data) {
    this.data = data;
    return axios().put('email/subscription-tracking-settings', this.data);
  }
}

export default new MailService();
