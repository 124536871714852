export default ($axios) => ({
  fetch(query) {
    return $axios.get('/chat/macros', { params: query });
  },
  store(payload) {
    return $axios.post('/chat/macros', payload);
  },
  update(macroId, payload) {
    return $axios.put(`/chat/macros/${macroId}`, payload);
  },
  destroy(macroId) {
    return $axios.delete(`/chat/macros/${macroId}`);
  },
});
