export default {
  state: {
  },
  mutations: {
  },
  actions: {
    exportSmsMessages({ commit, state }, query) {
      return this._vm.$api.sms.messages.export(query);
    },
    fetchSmsReports({ commit, state }, query) {
      return this._vm.$api.sms.reports.fetch(query);
    },
    downloadSmsReport({ commit, state }, id) {
      return this._vm.$api.sms.reports.download(id);
    },
    fetchSmsMessages({ commit, state }, query) {
      return this._vm.$api.sms.messages.fetch(query);
    },
    fetchSmsInsights({ commit, state }, query) {
      return this._vm.$api.sms.messages.insights(query);
    },
    fetchSmsCampaigns({ commit, state }, query) {
      return this._vm.$api.sms.campaigns.fetch(query);
    },
  },
};
