export default ($axios) => ({
  fetchPlans(query) {
    return $axios.get('/admin/plans', { params: query }).then((response) => response.data);
  },
  store(input) {
    return $axios.post('/admin/plans', input);
  },
  show(planId) {
    return $axios.get(`/admin/plans/${planId}`);
  },
  update(planId, input) {
    return $axios.put(`/admin/plans/${planId}`, input);
  },
  destroy(planId) {
    return $axios.delete(`/admin/plans/${planId}`);
  },
});
