export default ($axios) => ({
  fetch(query) {
    return $axios.get('/admin/rcs/messages', { params: query }).then((response) => response.data);
  },
  insights(input) {
    return $axios.post('/admin/rcs/insights', input).then((response) => response.data);
  },
  sintetic(input) {
    return $axios.post('/admin/rcs/sintetic', input).then((response) => response.data);
  },
});
