export default {
  install(Vue) {
    let ffmpeg;
    let fetch;

    async function processFFmpeg(file, type, params, resolve) {
      const time = new Date().getTime();
      const inputName = `input${time}.data`;
      const outputName = `output${time}.${type}`;

      console.log('ffmpeg:#4', { time, inputName, outputName }, ['-i', inputName, ...params, outputName]);

      ffmpeg.FS('writeFile', inputName, await fetch(file));
      // await ffmpeg.run('-i', inputName, 'output.mp4');
      await ffmpeg.run.apply(null, ['-i', inputName, ...params, outputName]);
      console.log('ffmpeg:#5 resolve');
      resolve(ffmpeg.FS('readFile', outputName));
    }

    Vue.prototype.$ffmpeg = (file, type, params = []) => new Promise((resolve, reject) => {
      const base = `${window.location.origin}/${process.env.BASE_URL}`;
      const path = `${base}assets/ffmpeg/ffmpeg.min.js`;

      if (fetch) {
        processFFmpeg(file, type, params, resolve);
      } else {
        window.FFMPEG_BASE = base;

        let loader = document.createElement('script');
        loader.async = true;
        loader.onload = async () => {
          document.body.removeChild(loader);
          loader = null;

          const f = window.FFmpeg;
          ffmpeg = f.createFFmpeg({ log: true });
          await ffmpeg.load();
          fetch = f.fetchFile;
          processFFmpeg(file, type, params, resolve);
        };
        loader.src = path;
        document.body.appendChild(loader);
      }
    });
  },
};
