export default {
  state: {
  },
  mutations: {
  },
  actions: {
    fetchFlows({ commit, state }, query) {
      return this._vm.$api.flow.fetch(query);
    },
    fetchFlowStreams({ commit, state }, query) {
      return this._vm.$api.flow.streams.fetch(query);
    },
    fetchFlowStream({ commit, state }, streamId) {
      return this._vm.$api.flow.streams.find(streamId);
    },
    cancelFlowStream({ commit, state }, streamId) {
      return this._vm.$api.flow.streams.cancel(streamId);
    },
    fetchFlowInsights({ commit, state }, query) {
      return this._vm.$api.flow.streams.insights(query);
    },
  },
};
