export default function (str) {
  /* eslint no-bitwise: ["error", { "allow": ["<<", ">>", "&"] }] */
  let hash = 0;
  let colour = '#';

  const j = str.length;

  for (let i = 0; i < j; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xFF;
    colour += `00${value.toString(16)}`.substr(-2);
  }

  return colour;
}
