import oauth2Routes from './oauth2';

console.log('Teste: ', window.$rebranding);

const routes = [
  {
    path: '/',
    component: () => import('../views/auth/Index.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/Login.vue'),
        meta: {
          guest: true,
        },
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/auth/Register.vue'),
        meta: {
          guest: true,
        },
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../views/auth/ResetPassword.vue'),
        meta: {
          guest: true,
        },
      },
      {
        path: '/reset-verification/:token',
        name: 'ResetVerification',
        component: () => import('../views/auth/ResetVerification.vue'),
        meta: {
          guest: true,
        },
      },
      {
        path: '/invitation/:token',
        name: 'Invitation',
        component: () => import('../views/auth/Invitation.vue'),
        meta: {
          guest: true,
        },
      },
      // {
      //   path: '/2fa',
      //   name: 'TwoFactor',
      //   component: () => import('../views/rebranding/auth/TwoFactor.vue'),
      // },
      ...oauth2Routes,
    ],
  },
];

export default routes;
