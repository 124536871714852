export default ($axios) => ({
  fetch(query) {
    return $axios.get('/sms/messages', { params: query }).then((response) => response.data);
  },
  export(input) {
    return $axios.post('/sms/reports', input).then((response) => response.data);
  },
  insights(input) {
    return $axios.post('/sms/messages/insights', input).then((response) => response.data);
  },
});
