export default {
  state: {
  },
  mutations: {
  },
  actions: {
    exportMailMessages({ commit, state }, query) {
      return this._vm.$api.mail.messages.export(query);
    },
    fetchMailReports({ commit, state }, query) {
      return this._vm.$api.mail.reports.fetch(query);
    },
    downloadMailReport({ commit, state }, id) {
      return this._vm.$api.mail.reports.download(id);
    },
    fetchMailMessages({ commit, state }, query) {
      return this._vm.$api.mail.messages.fetch(query);
    },
    fetchMailInsights({ commit, state }, query) {
      return this._vm.$api.mail.messages.insights(query);
    },
    fetchMailCampaigns({ commit, state }, query) {
      return this._vm.$api.mail.campaigns.fetch(query);
    },
  },
};
