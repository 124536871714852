<template>
  <div class="content">
    <PageHeader :title="$tc('generic-str.detail', 2)" />
    <div class="page-content container-fluid">
      <properties-card />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import PropertiesCard from '@/components/rcs/PropertiesCard.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    PropertiesCard,
  },
  data() {
    return {};
  },
};
</script>
