export default {
  state: {
    omniPlan: null,
    list: {},
  },
  mutations: {
    fetchOmniPlan(state, plans) {
      state.omniPlan = plans[0];
    },
    fetchPlans(state, plans) {
      console.log('Plans', plans);
      if (plans.data) {
        plans.data.forEach((plan) => {
          state.list[plan.service] = plan;
        });
      }
    },
  },
  actions: {
    fetchOmniPlan({ commit, state }) {
      if (state.omniPlan === null) {
        return this._vm.$api.plans.fetchPlans({ service: 'omni' }).then((plans) => {
          commit('fetchOmniPlan', plans);
        });
      }
      return state.plans;
    },
    fetchPlans({ commit, state }, query) {
      return this._vm.$api.plans.fetchPlans(query).then((plans) => {
        commit('fetchPlans', plans);
      });
    },
    fetchPlan({ commit, state }, planId) {
      return this._vm.$api.plans.fetchPlan(planId);
    },
  },
};
