import SmsQueue from '../views/sms/Queue.vue';
// import SmsUsage from '../views/sms/Usage.vue';
import SmsHistory from '../views/sms/History.vue';
import SmsDetail from '../views/sms/Detail.vue';

const routes = [
  {
    name: 'SmsDashboard',
    path: 'dashboard',
    component: () => import('../views/sms/Dashboard.vue'),
  },
  {
    name: 'SmsShortCodes',
    path: 'short-codes',
    component: () => (window.$rebranding.active ? import('../views/rebranding/sms/short-codes/Index.vue') : import('../views/sms/short-codes/Index.vue')),
  },
  {
    name: 'SmsShortCodesEdit',
    path: 'short-codes/:id',
    component: () => import('../views/sms/short-codes/Edit.vue'),
  },
  {
    name: 'SmsInsights',
    path: 'insights',
    component: () => import('../views/sms/Insights.vue'),
  },
  {
    name: 'SmsSend',
    path: 'send',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/sms/Send.vue') : import('../views/sms/Send.vue')),
  },
  {
    name: 'SmsSuccess',
    path: 'send/success',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/sms/Success.vue'),
  },
  {
    name: 'SmsCampaigns',
    path: 'campaigns',
    component: () => import('../views/sms/Campaigns.vue'),
  },
  {
    name: 'SmsExports',
    path: 'exports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/sms/Exports.vue'),
  },
  {
    name: 'SmsCampaignDetail',
    path: 'campaigns/:id',
    component: () => import('../views/sms/CampaignDetail.vue'),
  },
  {
    name: 'SmsTemplates',
    path: 'templates',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/sms/Templates.vue') : import('../views/sms/Templates.vue')),
  },
  {
    name: 'SmsQueue',
    path: 'queue',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: SmsQueue,
  },
  {
    name: 'SmsReports',
    path: 'reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rebranding/sms/reports/Index.vue'),
    children: [
      {
        name: 'SmsStatReport',
        path: 'stat',
        component: () => import('@/views/rebranding/sms/reports/Stat.vue'),
      },
      {
        name: 'SmsHistoryReport',
        path: 'history',
        component: () => import('@/views/rebranding/sms/reports/History.vue'),
      },
      {
        name: 'SmsDetail',
        path: 'history/:id',
        component: () => import('@/views/rebranding/sms/reports/Detail.vue'),
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
      },
      {
        name: 'SmsUsageReport',
        path: 'usage',
        component: () => import('@/views/rebranding/sms/reports/Usage.vue'),
      },
      {
        name: 'SmsCampaignReport',
        path: 'campaign',
        component: () => import('@/views/rebranding/sms/reports/Campaign.vue'),
      },
      {
        name: 'SmsReportExports',
        path: 'exports',
        component: () => import('@/views/rebranding/sms/reports/Export.vue'),
      },
    ],
  },
  {
    name: 'SmsUsage',
    path: 'usage',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/sms/Usage.vue') : import('../views/sms/Usage.vue')),
    children: [
      {
        name: 'SmsReport',
        path: '',
        component: () => import('@/views/rebranding/sms/Report.vue'),
      },
      {
        name: 'SmsStats',
        path: 'stats',
        component: () => import('@/views/rebranding/sms/stats/Index.vue'),
      },
      {
        name: 'SmsHistoryTab',
        path: 'history',
        component: () => import('@/views/rebranding/sms/history/History.vue'),
      },
    ],
  },
  {
    name: 'SmsHistory',
    path: 'history',
    component: SmsHistory,
  },
  {
    name: 'SmsDetail',
    path: 'history/:id',
    component: SmsDetail,
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
  },
  {
    name: 'Antifraude',
    path: 'Antifraude',
    meta: {
      role: 'admin,sales',
    },
    component: () => import('../views/sms/Antifraude.vue'),
  },
  {
    name: 'SmsDenylist',
    path: 'denylist',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/sms/Blacklist.vue') : import('../views/sms/Blacklist.vue')),
  },
  {
    name: 'SmsOptouts',
    path: 'optouts',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/sms/Optouts.vue'),
  },
];

export default routes;
