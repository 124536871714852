import Agents from './agents';
import Messages from './messages';
import Campaigns from './campaigns';
import Reports from './reports';
import Keywords from './keywords';

export default ($axios) => ({
  agents: Agents($axios),
  messages: Messages($axios),
  campaigns: Campaigns($axios),
  reports: Reports($axios),
  keywords: Keywords($axios),
});
