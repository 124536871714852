const routes = [
  {
    name: 'IntegrationsCatalog',
    path: '/',
    component: () => import('../views/integrations/Catalog.vue'),
  },
  {
    name: 'IntegrationsInstalled',
    path: 'installed',
    component: () => import('../views/integrations/Installed.vue'),
  },
  {
    name: 'IntegrationsDetail',
    path: ':id',
    component: () => import('../views/integrations/Detail.vue'),
  },
];

export default routes;
