import axios from '@/plugins/axios';

class CustomerService {
  getCustomers(data) {
    this.data = data;
    return axios().get('customers', { params: this.data }).then((response) => response.data);
  }

  getCustomer(id) {
    this.id = id;
    return axios().get(`customers/${id}`).then((response) => response.data);
  }

  searchCustomers(name) {
    this.a = 'a';
    return axios().get(`customers?name${name}`);
  }

  deleteCustomers(data) {
    this.data = data;
    return axios().delete('customers', { data: this.data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);
  }

  updateCustomer(data) {
    this.data = data;
    return axios().put(`customers/${data.id}`, this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  removeCustomer(id) {
    this.id = id;
    return axios().delete(`customers/${id}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  createCustomer(data) {
    this.data = data;
    return axios().post('customers', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  attachProduct(id, data) {
    this.data = data;
    return axios().post(`customers/${id}/attach`, this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  attachShortCode(id, data) {
    this.data = data;
    return axios().post(`customers/${id}/short-codes`, this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getProduct(customerId, productId) {
    this.customerId = customerId;
    return axios().get(`customers/${customerId}/products/${productId}`).then((response) => response.data);
  }

  getShortCode(customerId, shortCodeId) {
    this.customerId = customerId;
    return axios().get(`customers/${customerId}/short-codes/${shortCodeId}`).then((response) => response.data);
  }

  updateProduct(customerId, productId, data) {
    this.data = data;
    return axios().put(`customers/${customerId}/products/${productId}`, this.data).then((response) => response.data);
  }

  updateShortCode(customerId, shortCodeId, data) {
    this.data = data;
    return axios().put(`customers/${customerId}/short-codes/${shortCodeId}`, this.data).then((response) => response.data);
  }

  unsignProduct(customerId, productId) {
    this.customerId = customerId;
    return axios().delete(`customers/${customerId}/products/${productId}`).then((response) => response.data);
  }

  unsignShortCode(customerId, shortCodeId) {
    this.customerId = customerId;
    return axios().delete(`customers/${customerId}/short-codes/${shortCodeId}`).then((response) => response.data);
  }
}

export default new CustomerService();
