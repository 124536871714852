<template>
  <div>
    <Suggestions :suggestions="suggestions" :colors="colors" @sendSuggestion="submitSuggestion" />
    <div
      v-if="file"
      class="file-container"
      :style="{
        backgroundColor: colors.userInput.text,
        color: colors.userInput.bg
      }"
    >
      <span class="icon-file-message"
        ><img :src="icons.file.img" :alt="icons.file.name" height="15"
      /></span>
      {{ file.name }}
      <span class="delete-file-message" @click="cancelFile()"
        ><img
          :src="icons.closeSvg.img"
          :alt="icons.closeSvg.name"
          height="10"
          title="Remove the file"
      /></span>
    </div>
    <form
      class="sc-user-input"
      :class="{active: inputActive}"
      :style="{background: colors.userInput.bg}"
    >
      <div
        ref="userInput"
        role="button"
        tabIndex="0"
        contentEditable="true"
        :placeholder="placeholder"
        class="sc-user-input--text"
        :style="{color: colors.userInput.text}"
        @focus="setInputActive(true)"
        @blur="setInputActive(false)"
        @keydown="handleKey"
        @focusUserInput="focusUserInput()"
      ></div>
      <div class="sc-user-input--buttons">
        <div v-if="showEmoji && !isEditing" class="sc-user-input--button">
          <EmojiIcon :on-emoji-picked="handleEmojiPicked" :color="colors.userInput.text" />
        </div>
        <div v-if="showFile && !isEditing" class="sc-user-input--button">
          <FileIcons :on-change="handleFileSubmit" :color="colors.userInput.text" />
        </div>
        <div v-if="isEditing" class="sc-user-input--button">
          <UserInputButton
            :color="colors.userInput.text"
            tooltip="Cancel"
            @click.native.prevent="editFinish"
          >
            <IconCross />
          </UserInputButton>
        </div>
        <div class="sc-user-input--button">
          <UserInputButton
            v-if="isEditing"
            :color="colors.userInput.text"
            tooltip="Edit"
            @click.native.prevent="editText"
          >
            <IconOk />
          </UserInputButton>
          <UserInputButton
            v-else
            :color="colors.userInput.text"
            tooltip="Send"
            @click.native.prevent="submitText"
          >
            <IconSend />
          </UserInputButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EmojiIcon from './icons/EmojiIcon.vue';
import FileIcons from './icons/FileIcons.vue';
import UserInputButton from './UserInputButton.vue';
import Suggestions from './Suggestions.vue';
import FileIcon from './assets/file.svg';
import CloseIconSvg from './assets/close.svg';
import store from './store';
import IconCross from './components/icons/IconCross.vue';
import IconOk from './components/icons/IconOk.vue';
import IconSend from './components/icons/IconSend.vue';

export default {
  components: {
    EmojiIcon,
    FileIcons,
    UserInputButton,
    Suggestions,
    IconCross,
    IconOk,
    IconSend,
  },
  props: {
    icons: {
      type: Object,
      default() {
        return {
          file: {
            img: FileIcon,
            name: 'default',
          },
          closeSvg: {
            img: CloseIconSvg,
            name: 'default',
          },
        };
      },
    },
    showEmoji: {
      type: Boolean,
      default: () => false,
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    showFile: {
      type: Boolean,
      default: () => false,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Write something...',
    },
    colors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      inputActive: false,
    };
  },
  computed: {
    editMessageId() {
      return this.isEditing && store.state.editMessage.id;
    },
    isEditing() {
      return store.state.editMessage && store.state.editMessage.id;
    },
  },
  watch: {
    editMessageId() {
      if (store.state.editMessage !== null && store.state.editMessage !== undefined) {
        this.$refs.userInput.focus();
        this.$refs.userInput.textContent = store.state.editMessage.data.text;
      } else {
        this.$refs.userInput.textContent = '';
      }
    },
  },
  mounted() {
    this.$root.$on('focusUserInput', () => {
      if (this.$refs.userInput) {
        this.focusUserInput();
      }
    });
  },
  methods: {
    cancelFile() {
      this.file = null;
    },
    setInputActive(onoff) {
      this.inputActive = onoff;
    },
    handleKey(event) {
      if (event.keyCode === 13 && !event.shiftKey) {
        if (!this.isEditing) {
          this.submitText();
        } else {
          this.editText();
        }
        this.editFinish();
        event.preventDefault();
      } else if (event.keyCode === 27) {
        this.editFinish();
        event.preventDefault();
      }

      this.$emit('onType');
    },
    focusUserInput() {
      this.$nextTick(() => {
        this.$refs.userInput.focus();
      });
    },
    submitSuggestion(suggestion) {
      this.onSubmit({ author: 'me', type: 'text', data: { text: suggestion } });
    },
    checkSubmitSuccess(success) {
      if (Promise !== undefined) {
        Promise.resolve(success).then(
          (wasSuccessful) => {
            if (wasSuccessful === undefined || wasSuccessful) {
              this.file = null;
              this.$refs.userInput.innerHTML = '';
            }
          },
        );
      } else {
        this.file = null;
        this.$refs.userInput.innerHTML = '';
      }
    },
    submitText() {
      const text = this.$refs.userInput.textContent;
      const file = this.file;
      if (file) {
        this.submitTextWhenFile(text, file);
      } else if (text && text.length > 0) {
        this.checkSubmitSuccess(
          this.onSubmit({
            author: 'me',
            type: 'text',
            data: { text },
          }),
        );
      }
    },
    submitTextWhenFile(text, file) {
      if (text && text.length > 0) {
        this.checkSubmitSuccess(
          this.onSubmit({
            author: 'me',
            type: 'file',
            data: { text, file },
          }),
        );
      } else {
        this.checkSubmitSuccess(
          this.onSubmit({
            author: 'me',
            type: 'file',
            data: { file },
          }),
        );
      }
    },
    editText() {
      const text = this.$refs.userInput.textContent;
      if (text && text.length) {
        this.$emit('edit', {
          author: 'me',
          type: 'text',
          id: store.state.editMessage.id,
          data: { text },
        });
        this.editFinish();
      }
    },
    handleEmojiPicked(emoji) {
      this.checkSubmitSuccess(
        this.onSubmit({
          author: 'me',
          type: 'emoji',
          data: { emoji },
        }),
      );
    },
    handleFileSubmit(file) {
      this.file = file;
    },
    editFinish() {
      store.setState('editMessage', null);
    },
  },
};
</script>

<style scoped>
.sc-user-input {
  min-height: 55px;
  margin: 0px;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.sc-user-input--text {
  flex-grow: 1;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 18px;
  font-size: 15px;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  filter: contrast(15%);
  outline: none;
  cursor: text;
}

.sc-user-input--buttons {
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.sc-user-input--button {
  margin: 0 4px;
}

.sc-user-input.active {
  box-shadow: none;
  background-color: white;
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
}

.file-container {
  background-color: #f4f7f9;
  border-top-left-radius: 10px;
  padding: 5px 20px;
  color: #565867;
}

.delete-file-message {
  font-style: normal;
  float: right;
  cursor: pointer;
  color: #c8cad0;
}

.delete-file-message:hover {
  color: #5d5e6d;
}

.icon-file-message {
  margin-right: 5px;
}
</style>
