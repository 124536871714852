export default {
  state: {
  },
  mutations: {
  },
  actions: {
    exportWhatsappMessages({ commit, state }, query) {
      return this._vm.$api.whatsapp.messages.export(query);
    },
    fetchWhatsappReports({ commit, state }, query) {
      return this._vm.$api.whatsapp.reports.fetch(query);
    },
    downloadWhatsappReport({ commit, state }, id) {
      return this._vm.$api.whatsapp.reports.download(id);
    },
    fetchWhatsappMessages({ commit, state }, query) {
      return this._vm.$api.whatsapp.messages.fetch(query);
    },
    fetchWhatsappInsights({ commit, state }, query) {
      return this._vm.$api.whatsapp.messages.insights(query);
    },
    fetchWhatsappCampaigns({ commit, state }, query) {
      return this._vm.$api.whatsapp.campaigns.fetch(query);
    },
    fetchWhatsappNumbers({ commit, state }, query) {
      return this._vm.$api.whatsapp.numbers.fetch(query);
    },
    fetchWhatsappTemplates({ commit, state }, query) {
      return this._vm.$api.whatsapp.templates.fetch(query);
    },
  },
};
