export default function (value) {
  let max = value.indexOf('?');

  // console.log('live_serive_filters_fileExtension', max);

  if (max !== -1) value = value.substring(0, max);

  max = value.lastIndexOf('.');

  if (max === -1) return 'Arquivo';

  return value.substring(max + 1);
}
