const routes = [
  {
    name: 'Suport',
    path: 'support',
    component: () => (window.$rebranding.active ? import('../views/rebranding/support/Support.vue') : import('../views/support/Support.vue')),
  },
  {
    name: 'OpenTicket',
    path: 'new-ticket',
    component: () => (window.$rebranding.active ? import('../views/rebranding/support/OpenTicket.vue') : import('../views/support/OpenTicket.vue')),
  },
  {
    name: 'ViewTicket',
    path: ':id',
    component: () => (window.$rebranding.active ? import('../views/rebranding/support/ViewTicket.vue') : import('../views/support/ViewTicket.vue')),
  },
];

export default routes;
