export default {
  state: {
    list: [],
  },
  mutations: {
  },
  actions: {
    exportChatRooms({ commit, state }, query) {
      return this._vm.$api.omni.rooms.export(query);
    },
    fetchChatReports({ commit, state }, query) {
      return this._vm.$api.omni.reports.fetch(query);
    },
    storeRoom({ commit, state }, payload) {
      return this._vm.$api.omni.rooms.store(payload);
    },
    storeResumeMessage({ commit, state }, payload) {
      return this._vm.$api.omni.messages.store(payload);
    },
    fetchChatMacros({ commit, state }, query) {
      return this._vm.$api.omni.macros.fetch(query).then(
        (response) => Promise.resolve(response.data),
      ).catch(
        (error) => Promise.reject(error.response.data),
      );
    },
    storeChatMacros({ commit, state }, payload) {
      return this._vm.$api.omni.macros.store(payload).then(
        (response) => Promise.resolve(response.data),
      ).catch(
        (error) => Promise.reject(error.response.data),
      );
    },
    updateChatMacro({ commit, state }, payload) {
      return this._vm.$api.omni.macros.update(payload.id, payload).then(
        (response) => Promise.resolve(response.data),
      ).catch(
        (error) => Promise.reject(error.response.data),
      );
    },
    destroyChatMacro({ commit, state }, macroId) {
      return this._vm.$api.omni.macros.destroy(macroId).then(
        (response) => Promise.resolve(response.data),
      ).catch(
        (error) => Promise.reject(error.response.data),
      );
    },
    fetchChatDepartments({ commit, state }, query) {
      return this._vm.$api.omni.departments.fetch(query).then(
        (response) => Promise.resolve(response.data),
      ).catch(
        (error) => Promise.reject(error.response.data),
      );
    },
  },
};
