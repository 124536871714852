import Vue from 'vue';
import Vuex from 'vuex';
import countries from '@/json/countries.json';
import provinces from '@/json/provinces.json';
import currencies from '@/json/currencies.json';
import stages from './constants';
import auth from './auth.module';
import invoices from './modules/invoices';
import admin from './modules/admin';
import crop from './crop.module';
import account from './account.module';
import users from './modules/users';
import departments from './modules/departments';
import usageRecords from './modules/usageRecords';
import plans from './modules/plans';
import logs from './modules/logs.js';
import omni from './modules/omni.js';
import chat from './modules/chat.js';
import flow from './modules/flow.js';
import mail from './modules/mail.js';
import sms from './modules/sms.js';
import rcs from './modules/rcs.js';
import whatsapp from './modules/whatsapp.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    stage: stages.ALPHA,
    countries,
    provinces,
    currencies,
    menu: 0,
  },
  mutations: {
    changeDock(state, n) {
      state.menu = n;
    },
  },
  actions: {
  },
  modules: {
    auth,
    admin,
    invoices,
    account,
    crop,
    departments,
    users,
    usageRecords,
    plans,
    logs,
    omni,
    chat,
    flow,
    mail,
    sms,
    rcs,
    whatsapp,
  },
});
