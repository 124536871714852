const routes = [
  {
    name: 'Google',
    path: '/callback/google',
    component: () => import('../views/OAuth2.vue'),
    meta: {
      guest: true,
    },
  },
  {
    name: 'Facebook',
    path: '/callback/facebook',
    component: () => import('../views/OAuth2.vue'),
    meta: {
      guest: true,
    },
  },
];

export default routes;
