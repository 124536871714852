export default ($axios) => ({
  fetch(query) {
    return $axios.get('/customers', { params: query }).then((response) => response.data);
  },
  attachPlan(customerId, input) {
    return $axios.post(`/customers/${customerId}/plans`, input);
  },
  fetchCustomerPlan(accountId, planId) {
    return $axios.get(`/customers/${accountId}/plans/${planId}`);
  },
  detachPlan(accountId, planId) {
    return $axios.delete(`/customers/${accountId}/plans/${planId}`);
  },
  searchCompany(query) {
    return $axios.get('/customers/search', { params: query }).then((response) => response.data);
  },
  updateCustomer(customerId, input) {
    return $axios.put(`/customers/${customerId}`, input).then((response) => response.data);
  },
  destroy(customerId) {
    return $axios.delete(`/customers/${customerId}`).then((response) => response.data);
  },
  fetchInvoices(accountId, query) {
    return $axios.get(`/customers/${accountId}/invoices`, { params: query }).then((response) => response.data);
  },
});
