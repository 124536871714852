<template>
  <div :class="classes">
    <div ref="slot" class="toaster-slot">
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Toast from './Toast.vue';

export default Vue.extend({
  data() {
    return {
      isMounted: false,
      toasts: [],
    };
  },
  computed: {
    classes() {
      return ['toaster'];
    },
  },
  methods: {
    show(options) {
      const id = options.id ? `id:${options.id}` : null;

      if (id === null || !document.getElementById(id)) {
        if (!this.isMounted) {
          this.$mount();
          this.$el.id = id;
          document.body.appendChild(this.$el);
        }
        const toast = new Toast({
          propsData: options,
        });
        toast.$mount();
        this.$refs.slot.prepend(toast.$el);

        return toast;
      }

      return null;
    },
  },
  mounted() {
    this.isMounted = true;
  },
});
</script>
<style scoped lang="scss">
.toaster {
  z-index: 1100;
  position: fixed;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible;
  top: 0;
}
.toaster-slot {
  position: relative;
  display: block;
  &:empty {
    display: none !important;
  }
  position: absolute;
  max-width: 350px;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  top: 0.5rem;
  margin-left: auto;
}
</style>
