import authRoutes from './auth';
import adminRoutes from './admin';
import billingRoutes from './billing';
import users from './users';
import smsRoutes from './sms';
import mailRoutes from './mail';
import whatsappRoutes from './whatsapp';
import raRoutes from './ra';
import rcsRoutes from './rcs';
import numberRoutes from './numbers';
import contactRoutes from './contacts';
import integrationRoutes from './integrations';
import supportRoutes from './support';
import liveRoutes from './live';
import chatRoutes from './chat';
import flowRoutes from './flow';
import pipelineRoutes from './pipelines';
import planRoutes from './plans';

function group(prefix, routes) {
  return routes.map((route) => {
    const prefixedRoute = route;
    prefixedRoute.path = `${prefix}/${route.path}`;
    return prefixedRoute;
  });
}

export default [
  {
    path: '/webchat',
    name: 'ChatFront',
    component: () => import('../views/WebchatFront.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/accounts',
    component: () => import('../views/accounts/Layout.vue'),
    children: [
      {
        name: 'Accounts',
        path: '',
        component: () => import('../views/accounts/Index.vue'),
      },
      {
        name: 'AccountsCreate',
        path: 'create',
        component: () => import('../views/accounts/Create.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('../views/Layout.vue'),
    children: [
      {
        name: 'Home',
        path: '',
        component: () => import('../views/Home.vue'),
      },
      {
        name: 'Notifications',
        path: '/notifications',
        component: () => import('../views/rebranding/Notifications.vue'),
      },
      {
        name: 'Suporte',
        path: 'support',
        component: () => (window.$rebranding.active ? import('../views/rebranding/support/Support.vue') : import('../views/support/Support.vue')),
      },
      ...billingRoutes,
      {
        name: 'Webchat',
        path: '/chat/webchat',
        component: () => import('../views/Webchat.vue'),
      },
      {
        name: 'WebchatConfig',
        path: '/chat/configs',
        component: () => import('../views/WebchatConfig.vue'),
      },
      // {
      //   name: 'OpenTicket',
      //   path: 'support/new-ticket',
      //   component: () => import('../views/support/OpenTicket.vue'),
      // },
      {
        name: 'SMPP',
        path: 'sms/smpp',
        component: () => (window.$rebranding.active ? import('../views/rebranding/smpp/List.vue') : import('../views/smpp/List.vue')),
      },
      {
        name: 'TicketMessage',
        path: 'support/messages-ticket',
        component: () => import('../views/support/ViewTicket.vue'),
      },
      {
        name: 'Invoices',
        path: 'invoices',
        meta: {
          role: 'admin,owner,billing,developer,user,sales',
        },
        component: () => import('../views/rebranding/Invoices.vue'),
      },
      {
        name: 'InvoiceDetail',
        path: 'invoice/:id',
        meta: {
          role: 'admin,owner,billing,developer,user,sales',
        },
        component: () => import('../views/InvoiceDetail.vue'),
      },
      {
        name: 'MakeInvoice',
        path: 'new-invoice',
        meta: {
          role: 'admin,owner,billing,developer,user,sales',
        },
        component: () => import('../views/billing/MakeInvoice.vue'),
      },
      {
        name: 'Upgrade',
        path: 'billing/upgrade',
        meta: {
          role: 'admin,owner,billing,developer,user,sales',
        },
        component: () => (window.$rebranding.active ? import('../views/rebranding/billing/Upgrade.vue') : import('../views/billing/Upgrade.vue')),
      },
      {
        name: 'Usage',
        path: 'usage',
        meta: {
          role: 'admin,owner,billing,developer,user,sales',
        },
        component: () => import('../views/usage/Index.vue'),
      },
      {
        name: 'Catalog',
        path: 'catalog/list',
        component: () => import('../views/rebranding/catalog/ListCatalog.vue'),
      },
      {
        name: 'Product',
        path: 'catalog/list/registered',
        component: () => import('../views/rebranding/catalog/RegisteredProducts.vue'),
      },
      {
        name: 'GeneralSettings',
        path: 'settings',
        component: () => (window.$rebranding.active ? import('../views/rebranding/GeneralSettings.vue') : import('../views/GeneralSettings.vue')),
      },
      {
        name: 'ResourceSettings',
        path: 'settings/resources',
        component: () => import('../views/ResourceSettings.vue'),
      },
      {
        name: 'MessagesSettings',
        path: 'settings/messages',
        component: () => import('../views/MessagesSettings.vue'),
      },

      {
        name: 'Departamentos',
        path: 'departments',
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
        component: () => import('../views/Departments.vue'),
      },
      {
        name: 'Webhooks',
        path: 'webhooks',
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
        component: () => (window.$rebranding.active ? import('../views/rebranding/Webhooks.vue') : import('../views/Webhooks.vue')),
      },
      ...group('/admin', adminRoutes),
      ...group('/sms', smsRoutes),
      ...group('/mail', mailRoutes),
      ...group('/numbers', numberRoutes),
      ...group('/contacts', contactRoutes),
      ...group('/whatsapp', whatsappRoutes),
      ...group('/ra', raRoutes),
      ...group('/rcs', rcsRoutes),
      ...group('/flows', flowRoutes),
      ...group('/pipelines', pipelineRoutes),
      {
        name: 'Lookup',
        path: 'lookup',
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
        component: () => import('../views/lookup/Number.vue'),
      },
      {
        name: 'LookupList',
        path: 'lookup/list',
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
        component: () => import('../views/lookup/List.vue'),
      },
      ...users,
      {
        name: 'LookupUsage',
        path: 'lookup/usage',
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
        component: () => import('../views/lookup/Usage.vue'),
      },
      {
        name: 'UserSettings',
        path: 'user/settings',
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
        component: () => (window.$rebranding.active ? import('../views/rebranding/UserSettings.vue') : import('../views/UserSettings.vue')),
      },
      {
        name: 'campaigns',
        path: 'campaigns',
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
        component: () => import('../views/Campaigns.vue'),
      },
      ...group('/integrations', integrationRoutes),
      ...group('/support', supportRoutes),
      ...planRoutes,
      ...liveRoutes,
      ...chatRoutes,
      {
        name: 'Forbidden',
        path: '403',
        component: () => import('../views/errors/403.vue'),
      },
      {
        name: 'Channels',
        path: 'channels',
        component: () => (window.$rebranding.active ? import('../views/rebranding/Channels.vue') : import('../views/Channels.vue')),
      },
    ],
  },
  {
    path: '/live/simulator',
    name: 'Simulador',
    component: () => import('../views/live/simulator.vue'),
    meta: {
      both: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue'),
    meta: {
      guest: true,
      both: true,
    },
  },
  ...authRoutes,
  {
    path: '*',
    component: () => import('../views/errors/404.vue'),
  },
];
