<template>
  <div :class="classes" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <i class="zmdi zmdi-check-circle zmdi-hc-fw rounded mr-2"></i>
      <strong class="mr-auto">{{ title }}</strong>
      <button
        type="button"
        class="ml-2 mb-1 close"
        data-dismiss="toast"
        aria-label="Close"
        v-if="!requireInteraction"
      >
        <span aria-hidden="true" @click="hide">&times;</span>
      </button>
    </div>
    <div class="toast-body">{{ content }}</div>
    <div class="toast-footer row" v-if="requireInteraction">
      <template v-if="rejectBtn === null">
        <button class="btn-accept col-12" @click="accept">{{ acceptBtn || 'Confirmar' }}</button>
      </template>
      <template v-else>
        <button class="btn-accept col-6" @click="accept">{{ acceptBtn || 'Confirmar' }}</button>
        <button class="btn-reject col-6" @click="reject">{{ rejectBtn || 'Rejeitar' }}</button>
      </template>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: String,
    content: String,
    type: String,
    acceptBtn: String,
    rejectBtn: String,
    requireInteraction: Function,
  },
  data() {
    return {
      isMounted: false,
      visible: false,
    };
  },
  computed: {
    classes() {
      return [
        'toast', 'fade', this.type, {
          show: this.visible,
        },
      ];
    },
  },
  destroyed() {
    // Make sure we not in document any more
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  mounted() {
    this.isMounted = true;
    this.$nextTick(() => {
      setTimeout(() => {
        this.show();
      }, 16);
    });
  },
  methods: {
    accept() {
      this.hide();
      this.requireInteraction(true);
    },
    reject() {
      this.hide();
      this.requireInteraction(false);
    },
    show() {
      if (!this.visible) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.visible = true;
            if (!this.requireInteraction) {
              setTimeout(() => {
                this.hide();
              }, 4000);
            }
          }, 150);
        });
      }
    },
    hide() {
      if (this.visible) {
        this.visible = false;
        this.$nextTick(() => {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$destroy();
            }, 150);
          });
        });
      }
    },
  },
});
</script>
<style scoped lang="scss">
.toast {
  color: white;
  .toast-header {
    background-color: transparent;
    color: white;
    border: none;
    i {
      color: white;
    }
  }
  &.success {
    background-color: rgba(47, 191, 160, 0.85);
  }
  &.info {
    background-color: rgba(57, 154, 242, 0.85);
  }
  &.warning {
    background-color: rgba(255, 206, 103, 0.85);
  }
  &.danger {
    background-color: rgba(255, 92, 117, 0.85);
  }
  .btn-accept, .btn-reject {
    padding-bottom: .7em;
    padding-top: .8em;
    border: none;
    outline: none;
    opacity: .5;
    transition: .22s all ease;

    &:hover {
      opacity: 1;
    }
  }
  .btn-accept {
    background-color: rgba(255,255,255,.4);
  }
  .btn-reject {
    background-color: rgba(0,0,0,.4);
    color: #fff;
  }
}
</style>
