export default {
  state: {
    list: [],
  },
  mutations: {
  },
  actions: {
    fetchUsage({ commit, state }, payload) {
      return this._vm.$api.usage.fetchUsage(payload);
    },
  },
};
