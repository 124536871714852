import Rooms from './rooms';
import Tabs from './tabs';
import Tags from './tags';
import Agents from './agents';
import Reports from './reports';
import Messages from './messages';
import Macros from './macros';
import Departments from './departments';

export default ($axios) => ({
  rooms: Rooms($axios),
  tabs: Tabs($axios),
  tags: Tags($axios),
  agents: Agents($axios),
  reports: Reports($axios),
  messages: Messages($axios),
  macros: Macros($axios),
  departments: Departments($axios),
});
