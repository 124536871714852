export default ($axios) => ({
  fetchRooms(query) {
    return $axios.get('/omni/rooms', { params: query });
  },
  fetchRoom(roomId) {
    return $axios.get(`/omni/rooms/${roomId}`).then((response) => response.data);
  },
  fetchRoomMessages(roomId) {
    return $axios.get(`/omni/rooms/${roomId}/messages`).then((response) => response.data);
  },
  export(payload) {
    return $axios.post('/chat/reports', payload).then((response) => response.data);
  },
  store(payload) {
    return $axios.post('/omni/rooms', payload).then((response) => response.data);
  },
});
