const dropdown = {
  bind(element) {
    const el = element;

    el.toggle = () => {
      const parent = el.parentElement;
      const menu = parent.querySelector('.dropdown-menu');

      menu.classList.toggle('show');
    };
    el.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      el.toggle();
    });
  },
};

export default dropdown;
