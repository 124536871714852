import AccountService from '@/services/account.service';

const account = {
  namespaced: true,
  state: {
    id: '',
    name: '',
    balance: 0,
    auth_token: '',
    role: '',
    is_agent: false,
    type: '',
    whitelabel_id: null,
    plans: {
      rcs: null,
    },
  },
  actions: {
    info({ commit }) {
      AccountService.getInfo().then(
        (info) => {
          commit('infoSuccess', info);
          localStorage.setItem('info', JSON.stringify(info));
          return Promise.resolve(info);
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    select({ commit }, accountId) {
      return AccountService.switchAccount(accountId).then(
        (response) => {
          commit('selected', response);
          return Promise.resolve(response);
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
  mutations: {
    infoSuccess(state, info) {
      state.id = info.id;
      state.name = info.name;
      state.balance = info.balance;
      state.auth_token = info.auth_token;
      state.role = info.role;
      state.is_agent = info.is_agent;
      state.type = info.type;
      state.whitelabel_id = info.whitelabel_id;
      state.street = info.street;
      state.number = info.number;
      state.complement = info.complement;
      state.district = info.district;
      state.city = info.city;
      state.province = info.province;
      state.postal_code = info.postal_code;

      info.plans.forEach((plan) => {
        state.plans[plan.service] = plan;
      });

      // console.warn('$infoSuccess', { state, info });
    },
    selected(state, selectedAccount) {
      state.id = selectedAccount.id;
      state.name = selectedAccount.name;
      state.auth_token = selectedAccount.auth_token;
      state.role = selectedAccount.role;
      state.is_agent = selectedAccount.is_agent;
      state.balance = selectedAccount.balance;
      state.type = selectedAccount.type;
      state.whitelabel_id = selectedAccount.whitelabel_id;

      console.warn('$selected', { state, selectedAccount });
    },
    set(state, data) {
      state.id = data.id;
      state.name = 'x';
    },
  },
};

export default account;
