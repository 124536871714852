import axios from '@/plugins/axios';

class WhatsAppService {
  sendText(data) {
    this.data = data;
    return axios('v1').post('whatsapp/messages', this.data);
  }

  sendMedia(data) {
    this.data = data;
    return axios('v2').post('messages/sendMedia', this.data);
  }

  getClient(data) {
    this.data = data;
    return axios('v1').get('whatsapp/client', this.data);
  }

  exportMessages(data) {
    this.data = data;
    return axios().post('/reports', this.data);
  }

  reportsExport() {
    this.id = null;
    return axios().get('/reports');
  }

  downlodExport(id) {
    this.id = id;
    return axios().get(`/reports/${id}`, { responseType: 'arraybuffer' });
  }

  createClient(data) {
    this.data = data;
    return axios('v1').post('whatsapp/client', this.data);
  }

  optoutsSettings(data) {
    this.data = data;
    return axios('v1').get('optouts/settings', this.data);
  }

  optoutsMessage(data) {
    this.data = data;
    return axios('v1').put('optouts/settings', this.data);
  }

  createTextTemplate(data) {
    this.data = data;
    return axios('v1').post('whatsapp/templates', this.data);
  }

  getNewHistory(querystring) {
    console.info('getNewHistory', querystring);
    this.data = {};
    return axios('v1').get(`whatsapp/new/history?${querystring}`, {});
  }

  getNewCampaigns(data) {
    this.data = data;
    return axios('v1').get('whatsapp/new/campaign', { params: this.data }).then((response) => response.data);
  }

  createNewTemplate(data, businessId) {
    this.data = data;
    return axios('v1').post(`whatsapp/new/templates/${businessId}`, this.data);
  }

  deleteNewTemplate(id, data) {
    this.data = data;
    return axios('v1').delete(`whatsapp/new/template/${id}`, this.data);
  }

  getNewTemplates(businessId, data) {
    this.data = data;
    this.businessId = businessId;
    return axios('v1').get(`whatsapp/new/templates/${businessId}`, this.data);
  }

  getNewCatalog(phoneId, businessId, data) {
    this.data = data;
    this.businessId = businessId;
    return axios('v1').get(`whatsapp/new/catalog/${phoneId}/${businessId}`, this.data);
  }

  getNewCatalogProduct(phoneId, catalogId, data) {
    this.data = data;
    return axios('v1').get(`whatsapp/new/catalog/products/${phoneId}/${catalogId}`, this.data);
  }

  getTemplates(data) {
    this.data = data;
    return axios('v1').post('whatsapp/templates/list', this.data);
  }

  getNumbers(data) {
    this.data = data;
    return axios('v1').get('whatsapp/new/numbers', this.data);
  }

  deleteNumber(number) {
    this.number = number;
    return axios('v1').delete(`whatsapp/new/numbers/${number}`, []);
  }

  getMessages(data) {
    this.data = data;
    return axios('v1').post('whatsapp/messages/list', this.data);
  }

  filterMessages(data) {
    this.data = data;
    return axios().get('whatsapp/messages', { params: this.data }).then((response) => response.data);
  }

  filterNewMessages(data) {
    this.data = data;
    return axios().get('whatsapp/new/messages/list', { params: this.data }).then((response) => response.data);
  }

  getMessage(id) {
    this.id = id;
    return axios().get(`whatsapp/messages/${id}`);
  }

  getNewMessage(id) {
    this.id = id;
    return axios().get(`whatsapp/new/messages/${id}`);
  }

  getInsights(data) {
    this.data = data;
    return axios().post('whatsapp/messages/insights', this.data).then((response) => response.data);
  }

  chart(days) {
    this.a = 'a';
    return axios().get(`whatsapp/messages/charts?days=${days}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getCampaigns(data) {
    this.data = data;
    return axios().get('whatsapp/campaigns', { params: this.data }).then((response) => response.data);
  }

  deleteTemplate(id, data) {
    this.data = data;
    return axios('v1').delete(`whatsapp/templates/${id}`, this.data);
  }

  deleteTemplates(data) {
    this.data = data;
    console.log('deleteTemplates', { data });
    return axios('v1').delete('whatsapp/templates', { params: this.data });
  }

  sendTextTemplate(data) {
    this.data = data;
    return axios('v1').post('messages/sendTextTemplate', this.data);
  }

  sendMediaTemplate(data) {
    this.data = data;
    return axios('v2').post('messages/sendMediaTemplate', this.data);
  }
}

export default new WhatsAppService();
