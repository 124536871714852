const routes = [
  {
    path: '/',
    name: 'UnsubscribeFront',
    component: () => import('../views/UnsubscribeFront.vue'),
    meta: {
      guest: true,
    },
  },
];

export default routes;
