<template>
  <div class="content">
    <PageHeader :title="$t('email-history-component.title')">
      <ul class="actions top-right">
        <li>
          <router-link
            to="/mail/send"
            class="btn btn-success"
            aria-expanded="false"
            style="display: none"
          >
            {{$t('new-email-component.title')}}
          </router-link>
        </li>
      </ul>
    </PageHeader>
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="form-row">
            <div class="col-lg-4 col-md-4 m-top-10">
              <template>
                <div>
                  <date-range-picker
                    class="form-control"
                    value
                    :options="options"
                    @change="changeDate"
                  />
                </div>
              </template>
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <input
                class="form-control"
                :placeholder="$t('generic-str.lbl-recipient')"
                v-model="form.to"
              />
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <select class="form-control" v-model="form.status">
                <option selected value>Status</option>
                <option value="accepted">{{$tc('generic-str.status.lbl-pending', 1)}}</option>
                <option value="queued">{{$t('generic-str.status.lbl-queue')}}</option>
                <option value="sending">{{$t('generic-str.status.lbl-sending')}}</option>
                <option value="sent">{{$tc('generic-str.sent-a', 1)}}</option>
                <option value="failed">{{$t('generic-str.status.lbl-failure')}}</option>
                <option value="delivered">{{$t('generic-str.status.lbl-delivered')}}</option>
                <option value="bounced">{{$t('generic-str.status.lbl-bounced')}}</option>
                <option value="opened">{{$t('generic-str.status.lbl-open')}}</option>
                <option value="dropped">{{$tc('generic-str.status.lbl-dropped', 1)}}</option>
                <option value="deferred">{{$t('generic-str.status.lbl-postponed')}}</option>
                <option value="blocked">{{$t('generic-str.status.lbl-blocked')}}</option>
              </select>
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <div class="form-group">
                <div class="input-group">
                  <div class>
                    <button class="btn btn-danger" @click="fetch(1)">
                      {{$t('generic-str.filter')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body block-el p-0">
          <div class="responsive-table">
            <div class="text-center" v-if="messages.length == 0 && !loading">
              <i class="far fa-envelope font-size-80"></i>
              <h5 v-html="$t('warnings.email.none-email')"></h5>
              <router-link
                to="/mail/send"
                class="btn btn-success"
                aria-expanded="false"
              >
                {{$t('warnings.email.send-email')}}
              </router-link>
            </div>
            <table v-if="!loading && messages.length > 0" class="table">
              <thead class="bg-light">
                <tr>
                  <th scope="col">{{$t('generic-str.date')}}</th>
                  <th scope="col">{{$tc('generic-str.message', 1)}}</th>
                  <th scope="col">Status</th>
                  <th scope="col">Aberturas</th>
                  <th scope="col">Clicks</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="message in messages" :key="message.id">
                  <td>
                    <router-link :to="`history/${message.id}`">
                      {{ message.created_at | formatDate24 }}
                    </router-link>
                  </td>
                  <td class="text-truncate">
                    <span class="font-weight-500">Para: {{ message.to }}</span>
                    <br />
                    {{ message.subject }}
                  </td>
                  <td data-label="Status">
                    <span
                      v-if="message.status == 'accepted'"
                      class="badge badge-light"
                      >{{$tc('generic-str.status.lbl-pending', 1)}}</span
                    >
                    <span
                      v-else-if="message.status == 'queued'"
                      class="badge badge-secondary"
                      >{{$t('generic-str.status.lbl-queue')}}</span
                    >
                    <span
                      v-else-if="message.status == 'sending'"
                      class="badge badge-accent"
                      >{{$t('generic-str.status.lbl-sending')}}</span
                    >
                    <span
                      v-else-if="message.status == 'sent'"
                      class="badge badge-info"
                      >{{$t('generic-str.status.lbl-sent')}}</span
                    >
                    <span
                      v-else-if="message.status == 'failed'"
                      class="badge badge-danger"
                      v-tooltip.top="
                        `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                      "
                      >{{$t('generic-str.status.lbl-failure')}}</span
                    >
                    <span
                      v-else-if="message.status == 'delivered'"
                      class="badge badge-success"
                      >{{$t('generic-str.status.lbl-delivered')}}</span
                    >
                    <span
                      v-else-if="message.status == 'open'"
                      class="badge badge-info"
                      >Aberto</span
                    >
                    <span
                      v-else-if="message.status == 'click'"
                      class="badge badge-dark"
                      >Click</span
                    >
                    <span
                      v-else-if="message.status == 'bounce'"
                      class="badge badge-danger"
                      v-tooltip.top="
                        `O servidor de recebimento não pôde ou não aceitaria e-mails para este destinatário
                        permanentemente. Se um destinatário cancelou a assinatura de seus e-mails
                        anteriormente, a mensagem será descartada.`
                      "
                      >Devolvido</span
                    >
                    <span
                      v-else-if="message.status == 'bounced'"
                      class="badge badge-danger"
                      v-tooltip.top="
                        `O servidor de recebimento não pôde ou não aceitaria e-mails para este destinatário
                        permanentemente. Se um destinatário cancelou a assinatura de seus e-mails
                        anteriormente, a mensagem será descartada.`
                      "
                      >Devolvido</span
                    >
                    <span
                      v-else-if="message.status == 'opened'"
                      class="badge badge-success"
                      >{{$t('generic-str.status.lbl-open')}}</span
                    >
                    <span
                      v-else-if="message.status == 'dropped'"
                      class="badge badge-danger"
                      v-tooltip.top="
                        `Pode ter sido descartada pelos seguintes motivos: conteúdo de spam,
                        endereço não inscrito, endereço devolvido, inválido`
                      "
                      >{{$tc('generic-str.status.lbl-dropped', 1)}}</span
                    >
                    <span
                      v-else-if="message.status == 'deferred'"
                      class="badge badge-dark"
                      v-tooltip.top="
                        `O servidor receptor rejeitou temporariamente a mensagem. Será realizada
                        uma nova tentativa de envio`
                      "
                      >{{$t('generic-str.status.lbl-postponed')}}</span
                    >
                    <span
                      v-else-if="message.status == 'blocked'"
                      class="badge badge-danger"
                      v-tooltip.top="
                        `O servidor receptor não pôde ou não aceitou a mensagem temporariamente.
                        Se um destinatário cancelou a assinatura de seus e-mails anteriormente,
                        a mensagem será descartada.`
                      "
                      >{{$t('generic-str.status.lbl-blocked')}}</span
                    >
                  </td>
                  <td>{{ message.opens }}</td>
                  <td>{{ message.clicks }}</td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="loading"
              class="static qt-block-ui"
              style="padding: 120px"
            />
            <!-- <div
              v-if="loading"
              class="relative qt-block-ui"
              style="padding: 120px"
            /> -->
            <br />
            <pagination
              :lastPage="pages"
              :currentPage="form.page"
              @change="fetch"
            />
          </div>
        </div>
        <div class="card-footer">
          <button
            @click="exportMessages"
            class="btn btn-success"
            :class="{ 'qt-loader qt-loader-mini qt-loader-left': exporting }"
          >
            {{$t('generic-str.export')}} <!-- Exportar -->
          </button>
          <router-link to="/mail/exports" class="btn btn-info btn-export"
            >{{$t('sms.history.lbl-reports')}}</router-link> <!-- Relatórios Exportados -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';
import moment from 'moment';
import MailService from '@/services/mail.service';
import CustomerService from '@/services/customer.service';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      messages: [],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: true,
      beginDate: '',
      endDate: '',
      form: {
        page: 1,
        id: '',
        status: '',
        from: '',
        to: '',
        scheduled_at: {
          gte: '',
          lte: '',
        },
      },
      pages: 1,
      loading: false,
      exporting: false,
    };
  },
  created() {
    if (!this.$store.state.account.whitelabel_id) {
      this.fetchCustomers();
    }
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.loading = true;
      MailService.getMessages(this.form).then(
        (response) => {
          this.loading = false;
          this.messages = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.scheduled_at.gte = this.beginDate;
      this.form.scheduled_at.lte = this.endDate;
    },
    reportExport(blob, name) {
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, name);
      } else {
        // feature detection
        // Browsers that support HTML5 download attribute
        console.log(blob);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          document.body.removeChild(link);
        }, 1000);
      }
    },
    exportMessages() {
      this.exporting = true;
      MailService.exportMails({
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/mail/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
.no-mail {
  text-align: center;
  font-size: 100px;
  margin: 0px;
  padding: 0px;
  height: 0px;
}
</style>
