import Vue from 'vue';

const vm = new Vue({
  data: {
    active: true,
    // active: localStorage.getItem('rebrading') === '1',
  },
  created() {
    this.refresh();
  },
  watch: {
    active(active, prevActive) {
      localStorage.setItem('rebrading', active ? '1' : '0');
      // console.log({ active, prevActive, rebrading: localStorage.getItem('rebrading') });
      this.refresh();
    },
  },
  methods: {
    toggle(force) {
      if (typeof force === 'boolean') {
        this.active = force;
      } else {
        this.active = !this.active;
      }
    },
    refresh() {
      if (this.active) {
        document.body.classList.add('rebranding');
        document.body.classList.remove('en'); document.body.classList.remove('es'); document.body.classList.remove('pt');
        document.body.classList.add(localStorage.OptLang || 'pt');
      } else {
        document.body.classList.remove('rebranding');
        document.body.classList.remove('en'); document.body.classList.remove('es'); document.body.classList.remove('pt');
      }
    },
  },
});

window.$rebranding = vm;

export default {
  install(Vue) {
    Vue.prototype.$rebranding = vm;
  },
};
