export default ($axios) => ({
  fetch(query) {
    return $axios.get('/email/messages', { params: query }).then((response) => response.data);
  },
  export(input) {
    return $axios.post('/email/reports', input).then((response) => response.data);
  },
  insights(input) {
    return $axios.post('/email/messages/insights', input).then((response) => response.data);
  },
});
