import axios from 'axios';
import qs from 'qs';
import Auth from '@/api/auth';
import Admin from '@/api/admin';
import Billing from '@/api/billing';
import Users from '@/api/users';
import Departments from '@/api/departments';
import Plans from '@/api/plans';
import usageRecords from '@/api/usage';
import Logs from '@/api/logs';
import Omni from '@/api/omni';
import Flow from '@/api/flow';
import Sms from '@/api/sms';
import Rcs from '@/api/rcs';
import Mail from '@/api/mail';
import Whatsapp from '@/api/whatsapp';

export default {
  install: (app, options) => {
    const $axios = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      paramsSerializer: (params) => qs.stringify(params),
    });

    const refreshToken = (token) => {
      console.log('refreshToken', token);

      if (token) {
        $axios.defaults.headers.Authorization = `Bearer ${token}`;
      } else {
        // Remove token if needed
        $axios.defaults.headers.Authorization = null;
      }
    };

    $axios.interceptors.request.use(
      (config) => {
        const newConfig = config;
        if (config.params && config.params.export) newConfig.responseType = 'blob';
        return newConfig;
      },
      (error) => {
        console.log(error);
        Promise.reject(error);
      },
    );

    const repositories = {
      auth: Auth($axios),
      admin: Admin($axios),
      departments: Departments($axios),
      users: Users($axios),
      plans: Plans($axios),
      billing: Billing($axios),
      usage: usageRecords($axios),
      logs: Logs($axios),
      omni: Omni($axios),
      flow: Flow($axios),
      sms: Sms($axios),
      rcs: Rcs($axios),
      mail: Mail($axios),
      whatsapp: Whatsapp($axios),
      refreshToken,
    };
    app.prototype.$api = repositories;
  },
};

// export default {
//   install(Vue) {
//     const vm = Vue;
//     vm.prototype.$api = new Api();
//   },
// };
